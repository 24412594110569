import React from 'react';

import * as faSolid from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  onClick: () => void;
}

const UserMenuTriggerButton: React.FC<Props> = ({ onClick }) => {
  const CSSBlock = 'user-menu-trigger-button';

  return (
    <button className={`${CSSBlock}`} onClick={onClick}>
      <div className={` ${CSSBlock}__content`}>
        <span className={`${CSSBlock}__icon`}>
          <FontAwesomeIcon icon={faSolid.faUser} />
        </span>
      </div>
    </button>
  );
};

export default UserMenuTriggerButton;
