import React from 'react';

import ComingSoon from '@components/comingSoon/ComingSoon';

const ShopManagementDeliveryDays: React.FC = () => {
  const CSSBlock = 'shop-management-delivery-days';

  return (
    <div className={`${CSSBlock}`}>
      <ComingSoon />
    </div>
  );
};

export default ShopManagementDeliveryDays;
