import { useEffect, useState } from 'react';

import { NetworkService } from '@core/services/networkService/networkService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';

import { useAppSelector } from './appHooks';

const useFetchExcludedDateIntervals = (scheduleID?: string) => {
  const vendor = useAppSelector(selectVendor);
  const [excludedDateIntervals, setExcludedDateIntervals] = useState<
    [Date, Date][]
  >([]);

  useEffect(() => {
    if (vendor) {
      demmiRequest(
        NetworkService.VendorTimeSlotSchedule.getAllSchedules(vendor.docID),
      ).then(s => {
        const toExclude: [Date, Date][] = [];
        s.forEach(schedule => {
          if (schedule.scheduleID === scheduleID) return;
          toExclude.push([schedule.startDate, schedule.endDate]);
        });
        setExcludedDateIntervals(toExclude);
      });
    }
  }, [vendor, scheduleID]);

  return excludedDateIntervals;
};

export default useFetchExcludedDateIntervals;
