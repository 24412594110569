import {
  CollectionReference,
  DocumentData,
  collection,
} from 'firebase/firestore';
import { ReactNode, createContext, useContext } from 'react';

import { FSCollectionNames } from '@core/services/networkService/networkService.helper';
import { FSLogsImageResizing } from '@models/logs.model';
import { DemmiFS } from '@subhanhabib/demmilib';

import { db } from '../firebase';

export interface FirestoreProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState,
);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface FirestoreContextModel {
  vendorsCollection: CollectionReference<DemmiFS.FSVendor>;
  productsCollection: CollectionReference<DemmiFS.FSProduct>;
  variantsCollection: (
    pathSegments?: string[],
  ) => CollectionReference<DemmiFS.FSProductVariant>;
}

export const FirestoreContext = createContext<FirestoreContextModel>(
  {} as FirestoreContextModel,
);

export function useFirestore(): FirestoreContextModel {
  return useContext(FirestoreContext);
}

export const createCollection =
  <T = DocumentData,>(collectionName: string) =>
  (pathSegments: string[] = []) => {
    return collection(
      db,
      collectionName,
      ...pathSegments,
    ) as CollectionReference<T>;
  };

export const FSCollections = {
  Demmi: createCollection<DemmiFS.DemmiConstants>(FSCollectionNames.DEMMI)(),
  Coupons: createCollection<DemmiFS.FSCoupon>(FSCollectionNames.COUPONS)(),
  Users: createCollection<DemmiFS.FSUser>(FSCollectionNames.USERS)(),
  RefundRequests: createCollection<DemmiFS.FSRefundRequest>(
    FSCollectionNames.REFUND_REQUEST,
  )(),
  Notifications: createCollection<DemmiFS.FSNotificationVendor>(
    FSCollectionNames.NOTIFICATIONS,
  )(),
  LogsImageResizing: createCollection<FSLogsImageResizing>(
    FSCollectionNames.LOGS_IMAGE_RESIZING,
  ),

  // Orders
  Orders: createCollection<DemmiFS.FSOrder>(FSCollectionNames.ORDERS)(),
  OrderReviews: createCollection<DemmiFS.FSVendorReview>(
    FSCollectionNames.VENDORS,
  ),

  // Vendors
  Vendors: createCollection<DemmiFS.FSVendor>(FSCollectionNames.VENDORS)(),
  VendorTimeSlots: createCollection<DemmiFS.FSVendorTimeSlot>(
    FSCollectionNames.VENDOR_TIME_SLOTS,
  )(),
  VendorTimeSlotSchedule: createCollection<DemmiFS.FSVendorTimeSlotSchedule>(
    FSCollectionNames.VENDOR_TIME_SLOT_SCHEDULE,
  )(),
  VendorTimeSlotExceptions: createCollection<DemmiFS.FSVendorTimeSlotException>(
    FSCollectionNames.VENDOR_TIME_SLOT_EXCEPTIONS,
  )(),

  // Products
  Products: createCollection<DemmiFS.FSProduct>(FSCollectionNames.PRODUCTS)(),
  ProductVariants: createCollection<DemmiFS.FSProductVariant>(
    FSCollectionNames.PRODUCTS,
  ),

  // Demmi Support
  DemmiSupport: createCollection<DemmiFS.DemmiSupportVendor>(
    FSCollectionNames.DEMMI_SUPPORT,
  )(),
  DemmiSupportMessages: createCollection<DemmiFS.DemmiSupportMessage>(
    FSCollectionNames.DEMMI_SUPPORT,
  ),
};

export const FirestoreProvider = ({
  children,
}: FirestoreProviderProps): JSX.Element => {
  const values = {
    vendorsCollection: FSCollections.Vendors,
    productsCollection: FSCollections.Products,
    variantsCollection: FSCollections.ProductVariants,
  };

  return (
    <FirestoreContext.Provider value={values}>
      {children}
    </FirestoreContext.Provider>
  );
};
