import { QueryDocumentSnapshot, getDocs } from 'firebase/firestore';

import { DemmiFS, Logger } from '@subhanhabib/demmilib';

import { parseToSlot } from './_helper';
import { vendorSlotsQuery } from './_queries';

export class FSVendorTimeSlots {
  static _getSlots = async (
    vendorID: string,
  ): Promise<DemmiFS.VendorTimeSlot[]> => {
    Logger({ objs: { vendorID } }, this._getSlots);
    const querySnapshot = await getDocs(vendorSlotsQuery(vendorID));
    const slots: DemmiFS.VendorTimeSlot[] = [];

    querySnapshot.forEach(
      async (doc: QueryDocumentSnapshot<DemmiFS.FSVendorTimeSlot>) => {
        slots.push(parseToSlot(doc));
      },
    );
    return slots;
  };
}
