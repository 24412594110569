import { query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

export const vendorScheduleQuery = (vendorID: string, scheduleID: string) =>
  query(
    FSCollections.VendorTimeSlotSchedule,
    where('vendorID', '==', vendorID),
    where('scheduleID', '==', scheduleID),
  );

export const vendorAllSchedulesQuery = (vendorID: string) =>
  query(
    FSCollections.VendorTimeSlotSchedule,
    where('vendorID', '==', vendorID),
  );
