import { Timestamp } from 'firebase/firestore';
import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import FakePhone from '@components/fakePhone/FakePhone';
import { DemmiFS } from '@subhanhabib/demmilib';

import InventoryProductDetails from './productDetails/ProductDetails';
import InventoryProductVariants from './productVariants/ProductVariants';

interface Props {
  product: DemmiFS.Product;
  firestoreDemmi: DemmiFS.FSDemmiConstants;
  onChangeArchived: (id: string) => void;
}

const InventoryViewWithPreview: React.FC<Props> = ({
  product,
  firestoreDemmi,
  onChangeArchived,
}) => {
  const CSSBlock = 'inventory';

  return (
    <Swiper
      slidesPerView={'auto'}
      breakpoints={{
        600: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 2,
        },
        1500: {
          slidesPerView: 3,
        },
      }}
      spaceBetween={0}
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          const title = ['Preview', 'Details', 'Variants'];
          return `<span class="${className}">${title[index]}</span>`;
        },
      }}
      createElements
      modules={[Pagination]}
      className={`${CSSBlock}__swiper`}>
      <SwiperSlide className={`${CSSBlock}__pane ${CSSBlock}__pane--preview`}>
        <FakePhone
          app={1}
          product={{
            docID: '',
            vendorID: '',
            name: product.name,
            categoryID: product.categoryID ?? '',
            shortDescription: product.shortDescription,
            longDescription: product.longDescription.filter(
              value => value.length > 0,
            ),
            images: product.images,
            tags: product.tags,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            variants: [],
            isPublished: true,
            isArchived: false,
          }}
        />
      </SwiperSlide>

      <SwiperSlide className={`${CSSBlock}__pane`}>
        <InventoryProductDetails
          product={product}
          onChangeArchived={onChangeArchived}
          firestoreDemmi={firestoreDemmi}
        />
      </SwiperSlide>
      <SwiperSlide className={`${CSSBlock}__pane`}>
        <InventoryProductVariants product={product} />
      </SwiperSlide>
    </Swiper>
  );
};

export default InventoryViewWithPreview;
