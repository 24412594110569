import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DemmiFS } from '@subhanhabib/demmilib';

import InventoryProductDetails from './productDetails/ProductDetails';
import InventoryProductVariants from './productVariants/ProductVariants';

interface Props {
  product: DemmiFS.Product;
  firestoreDemmi: DemmiFS.FSDemmiConstants;
  onChangeArchived: (id: string) => void;
}

const InventoryViewDefault: React.FC<Props> = ({
  product,
  firestoreDemmi,
  onChangeArchived,
}) => {
  const CSSBlock = 'inventory';

  return (
    <Swiper
      slidesPerView={'auto'}
      breakpoints={{
        600: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 2,
        },
      }}
      spaceBetween={0}
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          const title = ['Details', 'Variants'];
          return `<span class="${className}">${title[index]}</span>`;
        },
      }}
      createElements
      modules={[Pagination]}
      className={`${CSSBlock}__swiper`}>
      <SwiperSlide className={`${CSSBlock}__pane`}>
        <InventoryProductDetails
          product={product}
          onChangeArchived={onChangeArchived}
          firestoreDemmi={firestoreDemmi}
        />
      </SwiperSlide>
      <SwiperSlide className={`${CSSBlock}__pane`}>
        <InventoryProductVariants product={product} />
      </SwiperSlide>
    </Swiper>
  );
};

export default InventoryViewDefault;
