import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import PageWithSidebar from '@components/page/PageWithSidebar';
import { RoutingViews, getViewFromPath } from '@core/services/routingService';

export const CSSBLOCK_ACCOUNT_SETTINGS = 'account-settings';

const AccountSettings: React.FC = () => {
  const [activePage, setActivePage] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const getIndex = (view?: RoutingViews) => {
    if (!view) return 0;

    switch (view) {
      case RoutingViews.SHOP_MANAGEMENT_IMAGES:
        return 1;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const view = getViewFromPath(location.pathname);
    setActivePage(getIndex(view));
  }, [location]);

  const sidebarButtons = [
    {
      id: RoutingViews.SHOP_MANAGEMENT_DETAILS,
      text: 'Details',
      faIcon: 'fa-address-card',
    },
  ];

  const sidebarOnClick = (id: string) => {
    navigate(id, { replace: true });
  };

  return (
    <PageWithSidebar
      sidebar={{
        buttons: sidebarButtons,
        active: activePage,
        title: 'Account Settings',
        onClick: sidebarOnClick,
      }}
      title={sidebarButtons[activePage].text}>
      <div className={`${CSSBLOCK_ACCOUNT_SETTINGS}`}>
        <Outlet />
      </div>
    </PageWithSidebar>
  );
};

export default AccountSettings;
