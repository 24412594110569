import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

import WidgetIncomingScheduleSlotItem from './WidgetIncomingScheduleSlotItem';
import { WidgetIncomingScheduleTimeSlot } from './_helper.ts';

interface Props {
  vendor: DemmiFS.Vendor;
  slot: WidgetIncomingScheduleTimeSlot;
  hasPassed?: boolean;
}

const WidgetIncomingScheduleSlot: React.FC<Props> = ({
  vendor,
  slot,
  hasPassed,
}) => {
  const CSSBlock = 'widget-incoming-schedule-slot';

  return (
    <div
      className={`${CSSBlock} ${hasPassed ? `${CSSBlock}--has-passed` : ''} ${slot.isOpen ? `${CSSBlock}--is-open` : ''}`}>
      <div className={`${CSSBlock}__time`}>
        <div className={`${CSSBlock}__time-text`}>{slot.name}</div>
        <div className={`${CSSBlock}__time-line`}></div>
      </div>
      <div className={`${CSSBlock}__orders-list`}>
        {slot.orders &&
          slot.orders.map((o, i) => (
            <WidgetIncomingScheduleSlotItem
              order={o}
              key={i}
              vendorID={vendor.docID}
            />
          ))}
      </div>
    </div>
  );
};

export default WidgetIncomingScheduleSlot;
