import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import PageWithSidebar from '@components/page/PageWithSidebar';
import {
  RoutingViews,
  getRouteFromPath,
  getViewFromPath,
} from '@core/services/routingService';
import { DemmiDictionary } from '@subhanhabib/demmilib';

export const CSSBLOCK_SHOP_MANAGEMENT = 'shop-management';

const ShopManagement: React.FC = () => {
  const [activePage, setActivePage] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const vendorDictionary = DemmiDictionary.getAllDictionaryValues(
    DemmiDictionary.Vendor,
    'farmer',
  );

  const getIndex = (view?: RoutingViews) => {
    if (!view) return 0;

    switch (view) {
      case RoutingViews.SHOP_MANAGEMENT_IMAGES:
        return 1;
      case RoutingViews.SHOP_MANAGEMENT_LOCATION:
        return 2;
      case RoutingViews.SHOP_MANAGEMENT_THEME:
        return 3;
      case RoutingViews.SHOP_MANAGEMENT_OPENING_HOURS:
        return 4;
      case RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE:
        return 5;
      case RoutingViews.SHOP_MANAGEMENT_DELIVERY_SLOTS:
        return 6;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const view = getViewFromPath(location.pathname, false, {
      exclude: [RoutingViews.SHOP_MANAGEMENT],
    });
    setActivePage(getIndex(view));
  }, [location]);

  const sidebarButtons = [
    {
      id: RoutingViews.SHOP_MANAGEMENT_DETAILS,
      text: 'Details',
      faIcon: 'fa-address-card',
    },
    {
      id: RoutingViews.SHOP_MANAGEMENT_IMAGES,
      text: 'Images',
      faIcon: 'fa-image',
    },
    {
      id: RoutingViews.SHOP_MANAGEMENT_LOCATION,
      text: `${vendorDictionary.labelShort} Location`,
      faIcon: 'fa-location-dot',
    },
    {
      id: RoutingViews.SHOP_MANAGEMENT_THEME,
      text: 'Theme',
      faIcon: 'fa-palette',
    },
    {
      id: RoutingViews.SHOP_MANAGEMENT_OPENING_HOURS,
      text: 'Opening Hours',
      faIcon: 'fa-regular fa-clock',
    },
    {
      id: RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE,
      text: 'Collection Time Slots',
      faIcon: 'fa-solid fa-user-clock',
      beta: true,
    },
    {
      id: RoutingViews.SHOP_MANAGEMENT_DELIVERY_SLOTS,
      text: 'Delivery Slots',
      faIcon: 'fa-solid fa-truck',
      alpha: true,
    },
  ];

  const sidebarOnClick = (id: string) => {
    navigate(id, { replace: true });
    navigate(getRouteFromPath(id as RoutingViews, {}), {
      replace: true,
    });
  };

  return (
    <PageWithSidebar
      sidebar={{
        buttons: sidebarButtons,
        active: activePage,
        title: 'Shop Management',
        onClick: sidebarOnClick,
      }}
      title={sidebarButtons[activePage].text}>
      <div className={`${CSSBLOCK_SHOP_MANAGEMENT}`}>
        <Outlet />
      </div>
    </PageWithSidebar>
  );
};

export default ShopManagement;
