import React, { PropsWithChildren } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiIconButton from '@demmi-ui/IconButton';

import Notifications from '../notifications/Notifications';

interface Props extends PropsWithChildren {
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const VendorWrapperHeader: React.FC<Props> = ({
  isMobileMenuActive,
  setIsMobileMenuActive,
}) => {
  const CSSBlock = 'vendor-wrapper';
  const vendor = useAppSelector(selectVendor);

  return (
    <div className={`${CSSBlock}__header`}>
      <div className={`vendor-title`}>{vendor && vendor.name}</div>
      <div className={`${CSSBlock}__action-buttons`}>
        <span className={`${CSSBlock}__action-button-menu`}>
          <DemmiIconButton
            faIcon="fa-regular fa-bars"
            onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
          />
        </span>
        <Notifications />
      </div>
    </div>
  );
};

export default VendorWrapperHeader;
