import React from 'react';

import { faStyles } from '@demmi-ui/IconButton';

interface SelfManaged extends Base {
  element: JSX.Element;
}
interface Managed extends Base {
  text: string;
  faIcon?: string;
  badge?: string;
}

interface Base {
  active?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}
type Props = Managed | SelfManaged;

const isSelfManaged = (props: Props): props is SelfManaged => {
  return 'element' in props;
};

const SidebarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ active, disabled, onClick, ...props }: Props, ref) => {
    const CSSBlock = 'sidebar-button';

    const el = isSelfManaged(props)
      ? props.element
      : ((props = props as Managed),
        (
          <>
            {props.faIcon && (
              <span className={`${CSSBlock}__icon`}>
                <i
                  className={
                    faStyles.find(item =>
                      (props as Managed).faIcon!.includes(item),
                    )
                      ? props.faIcon
                      : `fa-solid ${props.faIcon}`
                  }></i>
              </span>
            )}
            <span className={`${CSSBlock}__text`}>
              {props.badge && (
                <span className={`${CSSBlock}__badge`}>{props.badge}</span>
              )}
              {props.text}
            </span>
          </>
        ));

    return (
      <button
        className={`${CSSBlock} ${isSelfManaged(props) ? `${CSSBlock}-custom` : `${CSSBlock}-managed`} ${active ? `${CSSBlock}--active` : ``} `}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        {el}
      </button>
    );
  },
);

SidebarButton.displayName = 'SidebarButton';
export default SidebarButton;
