import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToSlot = (
  doc: QueryDocumentSnapshot<DemmiFS.FSVendorTimeSlot>,
): DemmiFS.VendorTimeSlot => {
  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSVendorTimeSlot),
  };
};
