import { format } from 'date-fns';
import React from 'react';

import { faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  chat: DemmiFS.DemmiSupportVendor;
  lastMessage?: DemmiFS.DemmiSupportMessage;
}

const DemmiSupportSidebarButton: React.FC<Props> = ({
  CSSBlock: parentCSSBlock,
  chat,
  lastMessage,
}) => {
  const CSSBlock = `${parentCSSBlock}-sidebar-button`;

  return (
    <div
      className={`${CSSBlock} 
      `}>
      <div className={`${CSSBlock}__text`}>{chat.title}</div>
      {chat.relatedOrderID && (
        <div className={`${CSSBlock}__order-info`}>{chat.relatedOrderID}</div>
      )}
      <div className={`${CSSBlock}__message`}>
        <FontAwesomeIcon size={'lg'} icon={faCheckDouble} />
        {lastMessage ? lastMessage.text : '-'}
      </div>
      <div className={`${CSSBlock}__time`}>
        {lastMessage?.timestamp
          ? format(
              DemmiFS.getDateFromFirestore(lastMessage.timestamp)!,
              'HH:mm',
            )
          : '-'}
      </div>
    </div>
  );
};

export default DemmiSupportSidebarButton;
