import { matchPath } from 'react-router-dom';

import { Logger } from '@subhanhabib/demmilib';

export enum SearchParams {
  PRODUCT_ID = 'productID',
  ORDER_ID = 'orderID',
  COUPON_ID = 'couponCode',
  REVIEW_ID = 'reviewID',
  ACTIVE_TAB = 'activeTab',
  SUPPORT_CHAT_ID = 'supportChatID',
  SM_SCHEDULE_ITEM_ID = 'smScheduleItemID',
}

export enum RouteParams {
  SM_SCHEDULE_ITEM_ID = 'smScheduleItemID',
}

const _pathSeparator = '/';
export enum RoutingViews {
  HOME = 'home',
  INVENTORY = 'inventory',
  ANALYTICS = 'analytics',
  REVIEWS = 'reviews',
  COUPONS = 'coupons',

  ORDERS = 'orders',
  ORDERS_INCOMING = `orders${_pathSeparator}incoming`,
  ORDERS_COMPLETED = `orders${_pathSeparator}completed`,
  ORDERS_REFUNDS = `orders${_pathSeparator}refunds`,
  ORDERS_ALL = `orders${_pathSeparator}all`,

  SHOP_MANAGEMENT = 'shop',
  SHOP_MANAGEMENT_DETAILS = `shop${_pathSeparator}details`,
  SHOP_MANAGEMENT_IMAGES = `shop${_pathSeparator}images`,
  SHOP_MANAGEMENT_LOCATION = `shop${_pathSeparator}location`,
  SHOP_MANAGEMENT_THEME = `shop${_pathSeparator}theme`,
  SHOP_MANAGEMENT_OPENING_HOURS = `shop${_pathSeparator}opening-hours`,

  SHOP_MANAGEMENT_COLLECTION_SCHEDULE = `shop${_pathSeparator}collection-schedule`,
  SHOP_MANAGEMENT_COLLECTION_SCHEDULE_PREVIEW = `shop${_pathSeparator}collection-schedule${_pathSeparator}preview`,
  SHOP_MANAGEMENT_COLLECTION_SCHEDULE_EDIT = `shop${_pathSeparator}collection-schedule${_pathSeparator}edit`,
  SHOP_MANAGEMENT_COLLECTION_SCHEDULE_CREATE = `shop${_pathSeparator}collection-schedule${_pathSeparator}create`,

  SHOP_MANAGEMENT_DELIVERY_SLOTS = `shop${_pathSeparator}delivery-slots`,

  ACCOUNT_SETTINGS = 'account-settings',

  DEMMI_SUPPORT = 'demmi-support',
}

const _generatePath = (
  root: RoutingViews,
  ...children: (RoutingViews | RouteParams)[]
) => {
  let path = `/${root}`;
  children.forEach(child => {
    if (Object.values(RouteParams).includes(child as RouteParams)) {
      path = `${path}/:${child}`;
    } else {
      path = `${path}/${child.substring(child.lastIndexOf(_pathSeparator) + 1)}`;
    }
  });
  return path;
};

export const routingPaths = {
  [RoutingViews.HOME]: `/${RoutingViews.HOME}`,
  [RoutingViews.INVENTORY]: `/${RoutingViews.INVENTORY}`,
  [RoutingViews.ANALYTICS]: `/${RoutingViews.ANALYTICS}`,
  [RoutingViews.REVIEWS]: `/${RoutingViews.REVIEWS}`,
  [RoutingViews.COUPONS]: `/${RoutingViews.COUPONS}`,

  // Orders
  [RoutingViews.ORDERS]: `/${RoutingViews.ORDERS}`,
  [RoutingViews.ORDERS_INCOMING]: _generatePath(
    RoutingViews.ORDERS,
    RoutingViews.ORDERS_INCOMING,
  ),
  [RoutingViews.ORDERS_COMPLETED]: _generatePath(
    RoutingViews.ORDERS,
    RoutingViews.ORDERS_COMPLETED,
  ),
  [RoutingViews.ORDERS_REFUNDS]: _generatePath(
    RoutingViews.ORDERS,
    RoutingViews.ORDERS_REFUNDS,
  ),
  [RoutingViews.ORDERS_ALL]: _generatePath(
    RoutingViews.ORDERS,
    RoutingViews.ORDERS_ALL,
  ),

  // Shop Management
  [RoutingViews.SHOP_MANAGEMENT]: `/${RoutingViews.SHOP_MANAGEMENT}`,
  [RoutingViews.SHOP_MANAGEMENT_DETAILS]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_DETAILS,
  ),
  [RoutingViews.SHOP_MANAGEMENT_IMAGES]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_IMAGES,
  ),
  [RoutingViews.SHOP_MANAGEMENT_LOCATION]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_LOCATION,
  ),
  [RoutingViews.SHOP_MANAGEMENT_THEME]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_THEME,
  ),
  [RoutingViews.SHOP_MANAGEMENT_OPENING_HOURS]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_OPENING_HOURS,
  ),

  // Collection Schedule
  [RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE,
  ),
  [RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_PREVIEW]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE,
    RouteParams.SM_SCHEDULE_ITEM_ID,
    RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_PREVIEW,
  ),
  [RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_EDIT]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE,
    RouteParams.SM_SCHEDULE_ITEM_ID,
    RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_EDIT,
  ),
  [RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_CREATE]: _generatePath(
    RoutingViews.SHOP_MANAGEMENT,
    RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE,
    RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_CREATE,
  ),

  // Delivery Slots
  [RoutingViews.SHOP_MANAGEMENT_DELIVERY_SLOTS]: `/${RoutingViews.SHOP_MANAGEMENT}/${RoutingViews.SHOP_MANAGEMENT_DELIVERY_SLOTS}`,

  [RoutingViews.ACCOUNT_SETTINGS]: `/${RoutingViews.ACCOUNT_SETTINGS}`,

  [RoutingViews.DEMMI_SUPPORT]: `/${RoutingViews.DEMMI_SUPPORT}`,
};

export const getRouteFromPath = (
  path: RoutingViews,
  replacements: { [key: string]: string },
) => {
  let p = routingPaths[path];
  Object.entries(replacements).forEach(([key, val]) => {
    p = p.replace(`:${key}`, val);
  });
  return p;
};

export const getViewFromPath = (
  path: string,
  exact = true,
  { exclude }: { exclude?: RoutingViews[] } = {},
) => {
  return Object.values(RoutingViews).find(view => {
    if (exclude && exclude.includes(view)) return false;
    return matchPath(
      exact ? routingPaths[view] : routingPaths[view] + '/*',
      path,
    );
  });
};
