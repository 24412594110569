import { QueryDocumentSnapshot, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { mapToVendorMappedSchedule } from '@helpers/schedule.helper';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { functions } from '../../../../firebase';
import { VendorMappedSchedule } from '../../../../models/vendorMappedSchedule.model';
import { CloudFunctions } from '../cloudFunctions';
import { parseToSlot } from './_helper';
import { vendorAllSchedulesQuery, vendorScheduleQuery } from './_queries';

export class FSVendorTimeSlotSchedules {
  static _getSchedule = async (
    vendorID: string,
    scheduleID: string,
  ): Promise<DemmiFS.VendorTimeSlotSchedule[]> => {
    Logger({ objs: { vendorID } }, this._getSchedule);
    const querySnapshot = await getDocs(
      vendorScheduleQuery(vendorID, scheduleID),
    );
    const slots: DemmiFS.VendorTimeSlotSchedule[] = [];

    querySnapshot.forEach(
      async (doc: QueryDocumentSnapshot<DemmiFS.FSVendorTimeSlotSchedule>) => {
        slots.push(parseToSlot(doc));
      },
    );

    return slots;
  };

  static _getAllSchedules = async (
    vendorID: string,
  ): Promise<VendorMappedSchedule[]> => {
    Logger({ objs: { vendorID } }, this._getAllSchedules);
    const querySnapshot = await getDocs(vendorAllSchedulesQuery(vendorID));
    const slots: DemmiFS.VendorTimeSlotSchedule[] = [];
    const scheduleIDs: string[] = [];
    querySnapshot.forEach(
      async (doc: QueryDocumentSnapshot<DemmiFS.FSVendorTimeSlotSchedule>) => {
        slots.push(parseToSlot(doc));
        if (scheduleIDs.indexOf(doc.data().scheduleID) === -1)
          scheduleIDs.push(doc.data().scheduleID);
      },
    );

    return scheduleIDs
      .map(id => {
        const days = slots.filter(slot => slot.scheduleID === id);
        const startDate = DemmiFS.getDateFromFirestore(days[0].startDate);
        const endDate = DemmiFS.getDateFromFirestore(days[0].endDate);
        if (!!startDate && !!endDate) {
          return mapToVendorMappedSchedule(
            vendorID,
            id,
            startDate,
            endDate,
            days,
          );
        } else {
          return;
        }
      })
      .filter(schedule => !!schedule) as VendorMappedSchedule[];
  };

  static _createSchedule = async (
    schedule: DemmiFS.FSVendorTimeSlotSchedule[],
  ): Promise<string[]> => {
    Logger({ objs: { schedule } }, this._createSchedule);
    return httpsCallable(
      functions,
      CloudFunctions.CREATE_VENDOR_COLLECTION_SCHEDULE,
    )({
      schedule,
    })
      .then(result => {
        Logger({ objs: result }, this._createSchedule);
        if (Array.isArray(result.data)) return result.data;
        else return [];
      })
      .catch(error => {
        Logger(
          { objs: { error }, type: DemmiLogType.error },
          this._createSchedule,
        );
        return [];
      });
  };

  static _updateSchedule = async (
    scheduleID: string,
    schedule: DemmiFS.VendorTimeSlotSchedule[],
  ): Promise<boolean> => {
    Logger({ objs: { scheduleID, schedule } }, this._updateSchedule);
    return httpsCallable(
      functions,
      CloudFunctions.UPDATE_VENDOR_COLLECTION_SCHEDULE,
    )({
      schedule,
    })
      .then(result => {
        Logger({ objs: result }, this._updateSchedule);
        return true;
      })
      .catch(error => {
        Logger(
          { objs: { error }, type: DemmiLogType.error },
          this._updateSchedule,
        );
        return false;
      });
  };

  static _deleteSchedule = async (
    vendorID: string,
    scheduleID: string,
  ): Promise<boolean> => {
    Logger({ objs: { scheduleID } }, this._deleteSchedule);
    return httpsCallable(
      functions,
      CloudFunctions.DELETE_VENDOR_COLLECTION_SCHEDULE,
    )({
      vendorID,
      scheduleID,
    })
      .then(result => {
        Logger({ objs: result }, this._deleteSchedule);
        return true;
      })
      .catch(error => {
        Logger(
          { objs: { error }, type: DemmiLogType.error },
          this._deleteSchedule,
        );
        return false;
      });
  };
}
