import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';

interface Props {
  scheduleStartDate: Date | undefined;
  scheduleEndDate: Date | undefined;
  onChangeStartDate: (val: Date | undefined) => void;
  onChangeEndDate: (val: Date | undefined) => void;
  excludeDateIntevals?: [Date, Date][];
}

const SMCollectionScheduleDatePicker: React.FC<Props> = ({
  scheduleStartDate,
  scheduleEndDate,
  onChangeStartDate,
  onChangeEndDate,
  excludeDateIntevals,
}) => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-date-picker`;
  const onDateChange = ([start, end]: [Date | null, Date | null]) => {
    onChangeStartDate(start ?? undefined);
    onChangeEndDate(end ?? undefined);
  };

  const excludedDates = useMemo(() => {
    if (!excludeDateIntevals) return [];
    return excludeDateIntevals.flatMap(([start, end]) => {
      const dates = [];
      const currentDate = new Date(start);
      while (currentDate <= end) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    });
  }, [excludeDateIntevals]);

  // Dynamically calculate the maximum end date based on the start date
  const maxEndDate = useMemo(() => {
    if (!scheduleStartDate || !excludeDateIntevals) return undefined;
    if (scheduleStartDate && scheduleEndDate) return undefined;

    for (const [start, _] of excludeDateIntevals) {
      if (scheduleStartDate < start) return start;
    }
    return undefined;
  }, [scheduleStartDate, scheduleEndDate, excludeDateIntevals]);

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}-title`}>Select your date range</div>
      <span style={{ position: 'relative' }}>
        <DatePicker
          minDate={new Date()}
          inline
          excludeDates={excludedDates}
          // highlightDates={
          //   blockedDates ? [...blockedDates, ...mockBlocked()] : mockBlocked()
          // }
          selectsRange
          monthsShown={2}
          shouldCloseOnSelect={false}
          onChange={onDateChange}
          selected={scheduleStartDate}
          {...(scheduleStartDate && { startDate: scheduleStartDate })}
          {...(scheduleEndDate && { endDate: scheduleEndDate })}
          {...(scheduleStartDate && { maxDate: maxEndDate })}
        />
      </span>
    </div>
  );
};

export default SMCollectionScheduleDatePicker;
