import { Timestamp, Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import { DemmiFS } from '@subhanhabib/demmilib';

import DemmiSupportChatThread from './_chatThread';
import DemmiSupportChatSnippetOrder from './_snippetOrder';

interface Props {
  CSSBlock: string;
  chatID: string;
}

const DemmiSupportChat: React.FC<Props> = ({
  CSSBlock: CSSBlockParent,
  chatID,
}) => {
  const CSSBlock = `${CSSBlockParent}-chat`;
  const vendor = useAppSelector(selectVendor);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const [chat, setChat] = useState<DemmiFS.DemmiSupportVendor>();
  const [messages, setMessages] = useState<DemmiFS.DemmiSupportMessage[]>();
  const [inputText, setInputText] = useState('');

  const sendMessage = () => {
    setIsSendingMessage(true);
    const message: DemmiFS.FSDemmiSupportMessage = {
      text: inputText,
      isSenderDemmi: false,
      timestamp: Timestamp.now(),
      read: false,
    };
    NetworkService.DemmiSupport.sendDemmiSupportMessage(chatID, message).then(
      () => setIsSendingMessage(false),
    );
  };

  useEffect(() => {
    if (vendor) {
      setMessages([]);
      setIsLoading(true);
      NetworkService.DemmiSupport.getDemmiSupportChat(chatID).then(c => {
        setChat(c);
        setIsLoading(false);
      });
    }
  }, [vendor, chatID]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const cb = (r: DemmiFS.DemmiSupportMessage[]) => setMessages(r);
      NetworkService.DemmiSupport.listenToDemmiSupportChatMessages(
        chatID,
        cb,
      ).then(u => (snapshotListener = u));
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor, chatID]);

  return (
    <div
      className={`${CSSBlock} ${isLoading ? `${CSSBlock}--is-loading` : ``}`}>
      <div className={`${CSSBlock}__title`}>
        {chat && chat.title}

        {chat?.relatedOrderID && (
          <DemmiSupportChatSnippetOrder
            onNavigate={() => {}}
            orderDocID={chat.relatedOrderID}
            CSSBlock={CSSBlock}
          />
        )}
      </div>

      <div className={`${CSSBlock}__content`}>
        <DemmiSupportChatThread
          CSSBlock={CSSBlock}
          isLoading={isLoading}
          messages={messages}
        />

        <div
          className={`${CSSBlock}__input-area ${
            isSendingMessage ? `${CSSBlock}__input-area--is-loading` : ``
          }`}>
          <DemmiInput
            placeholder="Message"
            type="text"
            value={inputText}
            onChangeValue={setInputText}
          />
          <DemmiIconButton faIcon={'fa-send'} onClick={sendMessage} />
          <ElementLoadingIndicator isLoading={isSendingMessage} />
        </div>

        <ElementLoadingIndicator isLoading={isLoading} />
      </div>
    </div>
  );
};

export default DemmiSupportChat;
