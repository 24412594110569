import { CalculatedDayDetail } from '@helpers/timeslots.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

export const getTodaysHours = (
  openingHours: DemmiFS.VendorOpeningHours,
): DemmiFS.OpeningHours | undefined => {
  switch (new Date().getDay()) {
    case 0:
      return openingHours.sun;
    case 1:
      return openingHours.mon;
    case 2:
      return openingHours.tue;
    case 3:
      return openingHours.wed;
    case 4:
      return openingHours.thu;
    case 5:
      return openingHours.fri;
    case 6:
      return openingHours.sat;
    default:
      return;
  }
};

export const widgetIncomingScheduleTimeInterval = 30; // minutes
export interface WidgetIncomingScheduleTimeSlot {
  value: number;
  name: string;
  isOpen: boolean;
  orders?: DemmiFS.Order[];
}

const _createDaySlots = () => {
  const daySlots: { [key: number]: WidgetIncomingScheduleTimeSlot } = {};
  for (let i = 0; i < 1440; i += widgetIncomingScheduleTimeInterval) {
    daySlots[i] = {
      value: i,
      name: DemmiHelpers.DemmiTime.timeToString(i),
      isOpen: false,
    };
  }
  return daySlots;
};

export const getTimes = (
  vendorID: string,
  dayDetail: CalculatedDayDetail | undefined,
  orders: DemmiFS.Order[],
): WidgetIncomingScheduleTimeSlot[] => {
  if (!dayDetail) return [];
  // if (hours.open === hours.close) return [];

  const daySlots = _createDaySlots();
  dayDetail.timeSlots.forEach(slot => {
    for (
      let i = slot.open;
      i < slot.close;
      i += widgetIncomingScheduleTimeInterval
    ) {
      if (!daySlots[i]) continue;
      daySlots[i].isOpen = true;
      daySlots[i].orders = orders.filter(
        o =>
          o.vendors[vendorID]?.collectionTime !== undefined &&
          o.vendors[vendorID].collectionTime! >= i &&
          o.vendors[vendorID].collectionTime! <
            i + widgetIncomingScheduleTimeInterval,
      );
    }
  });

  return Object.values(daySlots);
};
