import React from 'react';

import MobileMenu from '../mobileMenu/MobileMenu';

interface Props {
  CSSBlock: string;
  isMobileMenuActive: boolean;
  setIsMobileMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const VendorWrapperMobileMenu: React.FC<Props> = ({
  CSSBlock,
  isMobileMenuActive,
  setIsMobileMenuActive,
}) => {
  return (
    <div
      className={`${CSSBlock}__mobile-menu  ${isMobileMenuActive ? `${CSSBlock}__mobile-menu--active` : ''}`}>
      <div className={`mobile-menu__content`}>
        <MobileMenu onNavigated={() => setIsMobileMenuActive(false)} />
      </div>
    </div>
  );
};

export default VendorWrapperMobileMenu;
