import React, { useEffect } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import DemmiModalArea from '@demmi-ui/Modal/ModalArea';
import DemmiTextArea from '@demmi-ui/TextArea';

interface Props {
  state: number;
  CSSBlock: string;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  isDoingRequest: boolean;
}

const ModalCreateDemmiSupportTicketContent: React.FC<Props> = ({
  state,
  CSSBlock,
  title,
  setTitle,
  message,
  setMessage,
  setIsValid,
}) => {
  useEffect(() => {
    setIsValid(title.length > 3 && message.length > 3);
  }, [title, message]);

  return (
    <>
      <DemmiModalArea isDark>
        <div className={`${CSSBlock}__title`}>Create a Support Ticket</div>
        <div className={`${CSSBlock}__content`}>
          <DemmiCard>
            <DemmiInput
              type="text"
              value={title}
              onChangeValue={setTitle}
              placeholder="What would you like help with?"
            />
            <DemmiTextArea
              value={message}
              onChange={setMessage}
              placeholder="Give a brief description about your problem"
              maxHeight={16}
              maxLength={300}
            />

            {state === -1 && (
              <div className={`${CSSBlock}__error`}>Something went wrong.</div>
            )}
          </DemmiCard>
        </div>
      </DemmiModalArea>
    </>
  );
};

export default ModalCreateDemmiSupportTicketContent;
