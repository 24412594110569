import React, { useState } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from 'react-router-dom';

import LoadingIntro from '@components/loadingIntro/LoadingIntro';
import NotFound404 from '@pages/404/NotFound404';
import AccountSettings from '@pages/accountSettings/AccountSettings';
import Analytics from '@pages/analytics/Analytics';
import Coupons from '@pages/coupons/Coupons';
import DemmiSupport from '@pages/demmiSupport/DemmiSupport';
import Inventory from '@pages/inventory/Inventory';
import Login from '@pages/login/Login';
import Orders from '@pages/orders/Orders';
import OrdersAll from '@pages/orders/OrdersAll';
import OrdersCompleted from '@pages/orders/OrdersCompleted';
import OrdersIncoming from '@pages/orders/OrdersIncoming';
import OrdersRefunded from '@pages/orders/OrdersRefunded';
import Reviews from '@pages/reviews/Reviews';
import SMCollectionSchedule from '@pages/shopManagement/CollectionSchedule/CollectionSchedule';
import SMCollectionScheduleCreate from '@pages/shopManagement/CollectionSchedule/Create/Create';
import SMCollectionScheduleEditing from '@pages/shopManagement/CollectionSchedule/Editing/Editing';
import SMCollectionSchedulePreview from '@pages/shopManagement/CollectionSchedule/Preview/Preview';
import ShopManagementDeliveryDays from '@pages/shopManagement/DeliveryDays/DeliveryDays';
import ShopManagementDetails from '@pages/shopManagement/Details/Details';
import ShopManagementImages from '@pages/shopManagement/Images/Images';
import ShopManagementLocation from '@pages/shopManagement/Location/Location';
import ShopManagementOpeningHours from '@pages/shopManagement/OpeningHours/OpeningHours';
import ShopManagement from '@pages/shopManagement/ShopManagement';
import ShopManagementTheme from '@pages/shopManagement/Theme/Theme';

import { auth } from '../firebase';
import Home from '../pages/home/Home';
import AuthLayout from './AuthLayout';
import ProtectedRoute from './routeGuards/ProtectedRoute';
import UnprotectedRoute from './routeGuards/UnprotectedRoute';
import { RoutingViews, routingPaths } from './services/routingService';

const AppCore: React.FC = () => {
  const [isLoadingIntro, setIsLoadingIntro] = useState(true);
  const [_, setIsLoadingContent] = useState(false);

  // Necessary because auth is always null before initialising,
  // and Firebase doesn't return a promise for initialisation.
  const getUser = () =>
    new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={<AuthLayout />}
        loader={() => defer({ user: getUser() })}
        errorElement={<NotFound404 />}>
        <Route element={<UnprotectedRoute />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route path="/" element={<ProtectedRoute />}>
          <Route path={routingPaths[RoutingViews.HOME]} element={<Home />} />
          <Route
            path={routingPaths[RoutingViews.INVENTORY]}
            element={<Inventory />}
          />
          <Route
            path={routingPaths[RoutingViews.ANALYTICS]}
            element={<Analytics />}
          />
          <Route
            path={routingPaths[RoutingViews.REVIEWS]}
            element={<Reviews />}
          />
          <Route
            path={routingPaths[RoutingViews.COUPONS]}
            element={<Coupons />}
          />

          <Route path={routingPaths[RoutingViews.ORDERS]} element={<Orders />}>
            <Route
              path={routingPaths[RoutingViews.ORDERS_INCOMING]}
              element={<OrdersIncoming />}
            />
            <Route
              path={routingPaths[RoutingViews.ORDERS_COMPLETED]}
              element={<OrdersCompleted />}
            />
            <Route
              path={routingPaths[RoutingViews.ORDERS_REFUNDS]}
              element={<OrdersRefunded />}
            />
            <Route
              path={routingPaths[RoutingViews.ORDERS_ALL]}
              element={<OrdersAll />}
            />
          </Route>

          <Route
            path={routingPaths[RoutingViews.SHOP_MANAGEMENT]}
            element={<ShopManagement />}>
            <Route
              path={routingPaths[RoutingViews.SHOP_MANAGEMENT_DETAILS]}
              element={<ShopManagementDetails />}
            />
            <Route
              path={routingPaths[RoutingViews.SHOP_MANAGEMENT_IMAGES]}
              element={<ShopManagementImages />}
            />
            <Route
              path={routingPaths[RoutingViews.SHOP_MANAGEMENT_LOCATION]}
              element={<ShopManagementLocation />}
            />
            <Route
              path={routingPaths[RoutingViews.SHOP_MANAGEMENT_THEME]}
              element={<ShopManagementTheme />}
            />
            <Route
              path={routingPaths[RoutingViews.SHOP_MANAGEMENT_OPENING_HOURS]}
              element={<ShopManagementOpeningHours />}
            />

            {/* Collection Schedule */}
            <Route
              path={
                routingPaths[RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE]
              }
              element={<SMCollectionSchedule />}></Route>
            <Route
              path={
                routingPaths[
                  RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_CREATE
                ]
              }
              element={<SMCollectionScheduleCreate />}
            />
            <Route
              path={
                routingPaths[
                  RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_PREVIEW
                ]
              }
              element={<SMCollectionSchedulePreview />}
            />
            <Route
              path={
                routingPaths[
                  RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_EDIT
                ]
              }
              element={<SMCollectionScheduleEditing />}
            />

            <Route
              path={routingPaths[RoutingViews.SHOP_MANAGEMENT_DELIVERY_SLOTS]}
              element={<ShopManagementDeliveryDays />}
            />
          </Route>

          <Route
            path={routingPaths[RoutingViews.ACCOUNT_SETTINGS]}
            element={<AccountSettings />}></Route>

          <Route
            path={routingPaths[RoutingViews.DEMMI_SUPPORT]}
            element={<DemmiSupport />}
          />
        </Route>
      </Route>,
    ),
  );

  return (
    <>
      <RouterProvider router={router} />

      {/* Main app loading */}
      {/* <LoadingIntro
        onAnimStart={() => setIsLoadingContent(true)}
        onAnimEnd={() => {}}
      /> */}
      {isLoadingIntro && (
        <LoadingIntro
          onAnimStart={() => setIsLoadingContent(true)}
          onAnimEnd={() => setIsLoadingIntro(false)}
        />
      )}
    </>
  );
};

export default AppCore;
