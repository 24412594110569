import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { RouteParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { mapToVendorMappedSchedule } from '@helpers/schedule.helper';
import { VendorMappedSchedule } from '@models/vendorMappedSchedule.model';
import { CSSBLOCK_SHOP_MANAGEMENT } from '@pages/shopManagement/ShopManagement';
import { DemmiFS } from '@subhanhabib/demmilib';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';
import SMCollectionSchedulePreviewView from '../_partials/_previewView';

const SMCollectionSchedulePreview: React.FC = () => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-preview`;
  const { [RouteParams.SM_SCHEDULE_ITEM_ID]: scheduleID } = useParams();
  const vendor = useAppSelector(selectVendor);
  const [schedule, setSchedule] = useState<VendorMappedSchedule>();

  useEffect(() => {
    if (vendor && scheduleID) {
      demmiRequest(
        NetworkService.VendorTimeSlotSchedule.getSchedule(
          vendor.docID,
          scheduleID,
        ),
      ).then(o => {
        const startDate = DemmiFS.getDateFromFirestore(
          o.find(s => s.startDate)?.startDate,
        );
        const endDate = DemmiFS.getDateFromFirestore(
          o.find(s => s.endDate)?.endDate,
        );
        if (startDate && endDate) {
          const mapped = mapToVendorMappedSchedule(
            vendor.docID,
            scheduleID,
            startDate,
            endDate,
            o,
          );
          setSchedule(mapped);
        }
      });
    }
  }, [vendor, scheduleID]);

  return (
    <div className={`${CSSBLOCK_SHOP_MANAGEMENT}__content-wrapper ${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Your Schedule</div>
      {schedule && <SMCollectionSchedulePreviewView schedule={schedule} />}
    </div>
  );
};

export default SMCollectionSchedulePreview;
