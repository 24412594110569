import { format } from 'date-fns';
import React from 'react';

import { faCheck, faCheckDouble } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  message: DemmiFS.DemmiSupportMessage;
}

const DemmiSupportChatMessage: React.FC<Props> = ({
  CSSBlock: CSSBlockParent,
  message,
}) => {
  const CSSBlock = `${CSSBlockParent}-message`;

  return (
    <div
      className={`${CSSBlock} ${
        message.isSenderDemmi ? `${CSSBlock}--demmi` : ``
      }`}>
      <div className={`${CSSBlock}__text`}>{message.text}</div>
      <div className={`${CSSBlock}__timestamp`}>
        <FontAwesomeIcon
          size={'lg'}
          icon={message.read ? faCheckDouble : faCheck}
        />
        {message.timestamp
          ? format(
              DemmiFS.getDateFromFirestore(message.timestamp)!,
              'dd MMM yyyy, HH:mm:ss',
            )
          : '-'}
      </div>
    </div>
  );
};

export default DemmiSupportChatMessage;
