import React from 'react';
import PhoneInput, {
  Country,
  type Value,
} from 'react-phone-number-input/input';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import DemmiInputPhone from '@demmi-ui/InputPhone';
import DemmiTextArea from '@demmi-ui/TextArea';
import { DemmiDictionary } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  countryCode: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  contactNumber: Value | undefined;
  setContactNumber: React.Dispatch<React.SetStateAction<Value | undefined>>;
  shortDescription: string;
  setShortDescription: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
}

const ShopManagementDescription: React.FC<Props> = ({
  CSSBlock,
  countryCode,
  name,
  setName,
  contactNumber,
  setContactNumber,
  shortDescription,
  setShortDescription,
  description,
  setDescription,
}) => {
  const vendorDictionary = DemmiDictionary.getAllDictionaryValues(
    DemmiDictionary.Vendor,
    'farmer',
  );

  return (
    <DemmiCard
      title={`${vendorDictionary.labelShort} Details`}
      className={`${CSSBlock}__description`}>
      <DemmiInput
        type="text"
        placeholder="Vendor Name"
        value={name}
        onChangeValue={(val: string) => setName(val)}
      />
      <PhoneInput
        inputComponent={DemmiInputPhone}
        country={countryCode as Country}
        value={contactNumber}
        onChange={e => setContactNumber(e)}
      />
      <DemmiInput
        type="text"
        placeholder="Short Description"
        value={shortDescription}
        onChangeValue={(val: string) => setShortDescription(val)}
      />
      <DemmiTextArea
        placeholder="Long Description"
        value={description}
        onChange={val => setDescription(val)}
      />
    </DemmiCard>
  );
};

export default ShopManagementDescription;
