import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DemmiFS } from '@subhanhabib/demmilib';

interface ProductCategory {
  icon: IconDefinition;
  categoryName: string;
  products: DemmiFS.Product[];
}

interface CategorisedProducts {
  [key: string]: ProductCategory;
}

export const organiseProducts = (
  products: DemmiFS.Product[],
  firestoreDemmi: DemmiFS.DemmiConstants | undefined,
  filter?: string,
) => {
  const organised = products.reduce(
    (acc: CategorisedProducts, cur: DemmiFS.Product) => {
      if (filter && !cur.name.toLowerCase().includes(filter.toLowerCase()))
        return acc;
      if (acc[cur.categoryID]) {
        return {
          ...acc,
          [cur.categoryID]: {
            ...{ ...acc[cur.categoryID] },
            products: [...acc[cur.categoryID].products, cur],
          },
        };
      } else {
        const cat = DemmiFS.CategoriesHelper.getCategory(
          firestoreDemmi?.categories,
          cur.categoryID,
        );
        return {
          ...acc,
          [cur.categoryID]: {
            icon:
              cat?.child?.icon ||
              cat?.parent?.icon ||
              DemmiFS.CategoriesHelper.getIconFromCategoryID(
                firestoreDemmi?.categories,
                cur.categoryID,
              ),
            categoryName: cat?.child?.name || cat?.parent?.name || '',
            products: [cur],
          },
        };
      }
    },
    {},
  );
  return organised;
};
