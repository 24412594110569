import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import {
  getProductImage,
  getVendorImage,
} from '@core/services/networkService/functions/_storage';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  isVendor?: boolean;
  localLoadingIndicator?: boolean;
}

const AsyncImage: React.FC<Props> = ({
  src,
  isVendor,
  localLoadingIndicator,
  ...props
}) => {
  const CSSBlock = 'async-image';
  const vendor = useAppSelector(selectVendor);
  const [loadedSrc, setLoadedSrc] = useState<string>();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [completed, setCompleted] = useState(false);

  const loadWithGlobalLoader = () => {
    if (src && vendor) {
      demmiRequest(
        isVendor
          ? getVendorImage(vendor.docID, src)
          : getProductImage(vendor.docID, src),
      ).then(onSuccess);
    }
  };

  const loadWithLocalLoader = () => {
    if (src && vendor) {
      (isVendor
        ? getVendorImage(vendor.docID, src)
        : getProductImage(vendor.docID, src)
      )
        .then(onSuccess)
        .catch(e => {
          console.log(e);
        });
    }
  };

  const onSuccess = (url: string) => {
    const handleLoad = () => setLoadedSrc(url);
    const image = new Image();
    image.addEventListener('load', handleLoad);
    image.src = url;
    return () => image.removeEventListener('load', handleLoad);
  };

  useEffect(() => {
    setLoadedSrc(undefined);
    localLoadingIndicator ? loadWithLocalLoader() : loadWithGlobalLoader();
  }, [src, vendor]);

  const onImageLoaded = () => {
    setHasLoaded(true);
    setTimeout(() => setCompleted(true), 200);
  };

  return (
    <span className={`${CSSBlock} ${hasLoaded ? `${CSSBlock}--loaded` : ``}`}>
      {loadedSrc && <img src={loadedSrc} {...props} onLoad={onImageLoaded} />}

      {!completed && (
        <div className={`${CSSBlock}__loading-indicator-wrapper`}>
          <div className={`${CSSBlock}__loading-indicator`} />
        </div>
      )}
    </span>
  );
};

export default AsyncImage;
