import { Logger } from '@subhanhabib/demmilib';

export const Cloud_Function_Base_URL_DEV =
  'https://us-central1-demmi-dev.cloudfunctions.net';
export const Cloud_Function_Base_URL_PROD =
  'https://us-central1-demmi-prod.cloudfunctions.net';

export enum CloudFunctions {
  // Images
  DELETE_PRODUCT_IMAGE = 'http-vendorsPortal-deleteProductImage',
  DELETE_VENDOR_IMAGE = 'http-vendorsPortal-deleteVendorImage',
  // Vendor Collection Schedule
  CREATE_VENDOR_COLLECTION_SCHEDULE = 'http-vendorsPortal-createVendorCollectionSchedule',
  DELETE_VENDOR_COLLECTION_SCHEDULE = 'http-vendorsPortal-deleteVendorCollectionSchedule',
  UPDATE_VENDOR_COLLECTION_SCHEDULE = 'http-vendorsPortal-updateVendorCollectionSchedule',
  // Vendor
  UPDATE_VENDOR_OPENING_HOURS = 'http-vendorsPortal-updateVendorOpeningHours',
}

const getCloudFunctionBaseURL = () =>
  process.env.IS_PRODUCTION
    ? Cloud_Function_Base_URL_PROD
    : Cloud_Function_Base_URL_DEV;

export const getCloudFunctionURL = (func: CloudFunctions) => {
  Logger(
    { objs: { url: `${getCloudFunctionBaseURL()}/${func}` } },
    getCloudFunctionURL,
  );
  return `${getCloudFunctionBaseURL()}/${func}`;
};
