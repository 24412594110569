import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  text: string;
  icon?: IconDefinition;
  onClick: () => void;
}

const UserMenuDropdownButton: React.FC<Props> = ({ text, icon, onClick }) => {
  const CSSBlock = 'user-menu-dropdown-button';

  return (
    <button className={CSSBlock} onClick={onClick}>
      {icon && (
        <span className={`${CSSBlock}__icon`}>
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      <span className={`${CSSBlock}__text`}>{text}</span>
    </button>
  );
};

export default UserMenuDropdownButton;
