import React, { useEffect, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiModalArea from '@demmi-ui/Modal/ModalArea';
import { NEW_PRODUCT_VARIANT_TEMP_ID } from '@helpers/products.helper';
import ProductVariantInputs from '@pages/inventory/productVariants/_inputs';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  countryCode: string;
  setVariant: React.Dispatch<
    React.SetStateAction<DemmiFS.ProductVariant | undefined>
  >;
}

const ModalCreateVariantContent: React.FC<Props> = ({
  CSSBlock,
  countryCode,
  setVariant,
}) => {
  const [name, setName] = useState<string>('');
  const [minQuantity, setMinQuantity] = useState<number>(0);
  const [availableQuantity, setAvailableQuantity] = useState<number>(0);
  const [pricePerUnit, setPricePerUnit] = useState<number>(0);
  const [increments, setIncrements] = useState<number>(1);
  const [unit, setUnit] = useState<DemmiFS.UnitsHelper.WeightUnit>(
    DemmiFS.UnitsHelper.WeightUnit.gram,
  );
  // if unit === "weight_each"
  const [weightPerItem, setWeightPerItem] = useState<number>(0);
  const [unitPerItem, setUnitPerItem] =
    useState<DemmiFS.UnitsHelper.WeightUnit>(
      DemmiFS.UnitsHelper.WeightUnit.undefined,
    );

  useEffect(() => {
    setVariant(createModel());
  }, [
    name,
    minQuantity,
    availableQuantity,
    increments,
    pricePerUnit,
    unit,
    weightPerItem,
    unitPerItem,
  ]);

  const createModel = (): DemmiFS.ProductVariant => ({
    docID: NEW_PRODUCT_VARIANT_TEMP_ID,
    productID: '',
    name,
    availableQuantity,
    minQuantity,
    increments,
    pricePerUnit,
    unit,
    weightPerItem,
    unitPerItem,
  });

  return (
    <DemmiModalArea isDark>
      <div className={`${CSSBlock}__title`}>Create a new variant</div>
      <div className={`${CSSBlock}__content`}>
        <DemmiCard>
          <ProductVariantInputs
            currencyCode={DemmiFS.CurrencyHelper.getCurrencySymbolFromCountryCode(
              countryCode,
            )}
            name={name}
            setName={setName}
            minQuantity={minQuantity}
            setMinQuantity={setMinQuantity}
            availableQuantity={availableQuantity}
            setAvailableQuantity={setAvailableQuantity}
            pricePerUnit={pricePerUnit}
            setPricePerUnit={setPricePerUnit}
            increments={increments}
            setIncrements={setIncrements}
            unit={unit}
            setUnit={setUnit}
            weightPerItem={weightPerItem}
            setWeightPerItem={setWeightPerItem}
            unitPerItem={unitPerItem}
            setUnitPerItem={setUnitPerItem}
          />
        </DemmiCard>
      </div>
    </DemmiModalArea>
  );
};

export default ModalCreateVariantContent;
