import { Unsubscribe } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import {
  RoutingViews,
  SearchParams,
  routingPaths,
} from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import DemmiButton, { DemmiButtonSize } from '@demmi-ui/Button';
import { navigateTransitioned } from '@helpers/app.helper';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  orderDocID: string;
  onNavigate: () => void;
}

const DemmiSupportChatSnippetOrder: React.FC<Props> = ({
  CSSBlock: CSSBlockParent,
  orderDocID,
  onNavigate,
}) => {
  const CSSBlock = `${CSSBlockParent}-snippet-order`;
  const vendor = useAppSelector(selectVendor);
  const [order, setOrder] = useState<DemmiFS.Order>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const orderCallback = (order?: DemmiFS.Order) => {
    setOrder(order);
  };

  useEffect(() => {
    const snapshotListeners: Unsubscribe[] = [];
    if (vendor)
      NetworkService.Orders.listenToOrder(
        orderDocID,
        vendor.docID,
        orderCallback,
      ).then(u => snapshotListeners.push(u));
    return () => snapshotListeners.forEach(snapshot => snapshot());
  }, [vendor, orderDocID]);

  const viewOrder = () => {
    navigateTransitioned({
      dispatch,
      navigate,
      to: routingPaths[RoutingViews.ORDERS_ALL],
      search: {
        [SearchParams.ORDER_ID]: orderDocID!,
      },
    });
    onNavigate();
  };

  return order ? (
    <div className={`${CSSBlockParent}--snippet ${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>
        <span>Order Overview</span>
        <DemmiButton
          size={DemmiButtonSize.S}
          faIcon="fa-arrow-right-to-bracket"
          text={'View Order'}
          onClick={viewOrder}
        />
      </div>
      <div className={`${CSSBlockParent}__key-val`}>
        <div className={`${CSSBlockParent}__key`}>Order ID:</div>
        <div className={`${CSSBlockParent}__value`}>{order.orderID}</div>
      </div>
      <div className={`${CSSBlockParent}__key-val`}>
        <div className={`${CSSBlockParent}__key`}>Order created on:</div>
        <div className={`${CSSBlockParent}__value`}>
          {order.vendor?.collectionDate
            ? DemmiHelpers.Dates.formatCollectionDate(
                order.vendor.collectionDate,
              )
            : 'No date given'}
        </div>
      </div>
      <div className={`${CSSBlockParent}__key-val`}>
        <div className={`${CSSBlockParent}__key`}>
          Customer&apos;s collection date:
        </div>
        <div className={`${CSSBlockParent}__value`}>
          {order.vendor?.collectionDate
            ? DemmiHelpers.Dates.formatCollectionDate(
                order.vendor.collectionDate,
              )
            : 'No date given'}
        </div>
      </div>
    </div>
  ) : (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__error`}>Unable to find order</div>
    </div>
  );
};

export default DemmiSupportChatSnippetOrder;
