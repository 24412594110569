import React from 'react';

import ShopManagementThemeHueItem from './_hue-item';

interface Props {
  onHueChange: (hue: number) => void;
}

const ShopManagementThemeHues: React.FC<Props> = ({ onHueChange }) => {
  const CSSBlock = 'shop-management-theme';
  return (
    <div className={`${CSSBlock}__items`}>
      <ShopManagementThemeHueItem onClick={onHueChange} hue={0} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={20} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={40} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={60} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={80} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={100} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={120} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={140} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={160} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={180} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={200} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={220} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={240} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={260} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={280} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={300} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={320} />
      <ShopManagementThemeHueItem onClick={onHueChange} hue={340} />
    </div>
  );
};

export default ShopManagementThemeHues;
