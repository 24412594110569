import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@core/hooks/appHooks';
import useFetchCollectionSchedules from '@core/hooks/useFetchCollectionSchedules';
import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
  routingPaths,
} from '@core/services/routingService';
import { navigateTransitioned } from '@helpers/app.helper';

import SMCollectionScheduleList from './_list';

export const CSSBLOCK_SM_COLLECTION_SCHEDULE = 'sm-collection-schedule';

const SMCollectionSchedule: React.FC = () => {
  const CSSBlock = CSSBLOCK_SM_COLLECTION_SCHEDULE;
  const schedules = useFetchCollectionSchedules();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickScheduleEdit = (docID: string) => {
    navigateTransitioned({
      dispatch,
      navigate,
      to: getRouteFromPath(
        RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_EDIT,
        {
          [RouteParams.SM_SCHEDULE_ITEM_ID]: docID,
        },
      ),
    });
  };
  const onClickSchedulePreview = (docID: string) => {
    navigateTransitioned({
      dispatch,
      navigate,
      to: getRouteFromPath(
        RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_PREVIEW,
        {
          [RouteParams.SM_SCHEDULE_ITEM_ID]: docID,
        },
      ),
    });
  };

  const onClickAddNewSchedule = () => {
    navigateTransitioned({
      dispatch,
      navigate,
      to: routingPaths[RoutingViews.SHOP_MANAGEMENT_COLLECTION_SCHEDULE_CREATE],
    });
  };

  return (
    <div className={`${CSSBlock}`}>
      <SMCollectionScheduleList
        CSSBlock={CSSBlock}
        schedules={schedules}
        onClickEdit={onClickScheduleEdit}
        onClickPreview={onClickSchedulePreview}
        onClickAdd={onClickAddNewSchedule}
      />
    </div>
  );
};

export default SMCollectionSchedule;
