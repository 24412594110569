import React, { useEffect } from 'react';

import { DemmiHooks } from '@subhanhabib/demmilib';

import UserMenuDropdown from './_dropdown';
import UserMenuTriggerButton from './_triggerButton';

const UserMenu: React.FC = () => {
  const CSSBlock = 'user-menu';

  const gracefulClose = (postClose?: () => void) => {
    setTimeout(() => {
      setIsVisible(false);
      if (postClose) postClose();
    }, 0);
  };

  const { ref, isVisible, setIsVisible } =
    DemmiHooks.useComponentVisible<HTMLDivElement>(
      false,
      () => new Promise<void>(resolve => gracefulClose(() => resolve())),
    );

  useEffect(() => {
    const onKeyDown = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') gracefulClose(() => setIsVisible(false));
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const onClick = () => {
    if (isVisible) gracefulClose(() => setIsVisible(false));
    else setIsVisible(true);
  };

  return (
    <div className={`${CSSBlock}`}>
      <UserMenuTriggerButton onClick={onClick} />
      {isVisible && (
        <span ref={ref}>
          <UserMenuDropdown isOpen={isVisible} close={gracefulClose} />
        </span>
      )}
    </div>
  );
};

export default UserMenu;
