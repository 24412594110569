import React from 'react';
import { useNavigate } from 'react-router-dom';

import ThemeSwitcher from '@components/ThemeSwitcher';
import AsyncImage from '@components/asyncImage/AsyncImage';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import { getAppDate, getAppVersion, isProd } from '@helpers/app.helper';
import { navigateTransitioned } from '@helpers/app.helper';
import { useAuth } from '@providers/authProvider';

import UserMenuDropdownButton from './_dropdownButton';

interface Props {
  isOpen: boolean;
  close: () => void;
}

const UserMenuDropdown: React.FC<Props> = ({ isOpen, close }) => {
  if (!isOpen) return null;

  const CSSBlock = 'user-menu-dropdown';
  const vendor = useAppSelector(selectVendor);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { logOut } = useAuth();

  const onClickFarmManagement = () => {
    navigateTransitioned({
      dispatch,
      navigate,
      to: routingPaths[RoutingViews.SHOP_MANAGEMENT_DETAILS],
    });
    close();
  };

  const onClickSettings = () => {
    navigateTransitioned({
      dispatch,
      navigate,
      to: routingPaths[RoutingViews.ACCOUNT_SETTINGS],
    });
    close();
  };

  const onClickSupport = () => {
    navigateTransitioned({
      dispatch,
      navigate,
      to: routingPaths[RoutingViews.DEMMI_SUPPORT],
    });
    close();
  };

  const onClickLogout = () => {
    logOut(() => dispatch({ type: 'LOGOUT' }));
  };

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__user`}>
        <div className={`${CSSBlock}__user-image`}>
          {vendor && vendor.images.length > 0 && (
            <AsyncImage
              src={vendor.images[0].thumbnail}
              isVendor
              localLoadingIndicator
            />
          )}
        </div>
        <div className={`${CSSBlock}__user-info-stack`}>
          <div className={`${CSSBlock}__user-name`}>{vendor?.name}</div>
          <div className={`${CSSBlock}__user-email`}>
            {vendor?.contactNumber}
          </div>
        </div>
      </div>
      <div className={`${CSSBlock}__divider`} />
      <div className={`${CSSBlock}__section`}>
        <ThemeSwitcher />
      </div>
      <div className={`${CSSBlock}__divider`} />
      <div className={`${CSSBlock}__section`}>
        <UserMenuDropdownButton
          text="Shop Management"
          icon={faLight.faShop}
          onClick={onClickFarmManagement}
        />
        <UserMenuDropdownButton
          text="Account Settings"
          icon={faLight.faGear}
          onClick={onClickSettings}
        />
        <UserMenuDropdownButton
          text="Demmi Support"
          icon={faLight.faMessageBot}
          onClick={onClickSupport}
        />
      </div>
      <div className={`${CSSBlock}__divider`} />
      <div className={`${CSSBlock}__section`}>
        <UserMenuDropdownButton
          text="Logout"
          icon={faLight.faArrowRightFromBracket}
          onClick={onClickLogout}
        />
      </div>

      {!isProd() && (
        <div className={`${CSSBlock}__version`}>
          <div className={`${CSSBlock}__version-build`}>
            <span className={`${CSSBlock}__version-key`}>Build version</span>
            <span className={`${CSSBlock}__version-val`}>
              {getAppVersion()}
            </span>
          </div>
          <div className={`${CSSBlock}__version-date`}>
            <span className={`${CSSBlock}__version-key`}>Build date</span>
            <span className={`${CSSBlock}__version-val`}>{getAppDate()}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenuDropdown;
