import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

import DemmiSupportChatMessage from './_chatMessage';

interface Props {
  CSSBlock: string;
  isLoading: boolean;
  messages?: DemmiFS.DemmiSupportMessage[];
}

const DemmiSupportChatThread: React.FC<Props> = ({
  CSSBlock,
  isLoading,
  messages,
}) => {
  const messageList = (messages: DemmiFS.DemmiSupportMessage[]) =>
    messages.length > 0 ? (
      messages.map((message, i) => (
        <DemmiSupportChatMessage
          key={i}
          CSSBlock={CSSBlock}
          message={message}
        />
      ))
    ) : (
      <div className={`${CSSBlock}__no-messages`}>No messages (yet) ...</div>
    );

  return (
    <div className={`${CSSBlock}__messages`}>
      {isLoading ? (
        <></>
      ) : messages ? (
        messageList(messages)
      ) : (
        <div className={`${CSSBlock}__no-messages`}>
          You&apos;ve created a new chat!
        </div>
      )}
    </div>
  );
};

export default DemmiSupportChatThread;
