import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageWithSidebar from '@components/page/PageWithSidebar';
import { SearchParams } from '@core/services/routingService';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { DemmiFS } from '@subhanhabib/demmilib';

import OrderSidebarButton from './_sidebarButton';
import OrderItem from './orderItem/OrderItem';

interface Props {
  orders: DemmiFS.Order[];
  onClickRefresh: () => void;
}

const OrdersList: React.FC<Props> = ({ orders, onClickRefresh }) => {
  const [filteredOrders, setFilteredOrders] = useState<DemmiFS.Order[]>(orders);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState(
    DemmiFS.OrderHelper.SORT_BY.COLLECTION_TIME_ASC,
  );
  const [sortedOrders, setSortedOrders] = useState(
    DemmiFS.OrderHelper.sortOrders(filteredOrders, sortBy),
  );
  const [selectedOrder, setSelectedOrder] = useState<
    DemmiFS.Order | undefined
  >();

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);

  useEffect(() => {
    if (searchParams.get(SearchParams.ORDER_ID)) {
      setSelectedOrder(
        filteredOrders.find(
          o => o.docID === searchParams.get(SearchParams.ORDER_ID),
        ),
      );
    } else {
      setSelectedOrder(undefined);
    }
  }, [filteredOrders, searchParams]);

  useEffect(() => {
    setSortedOrders(DemmiFS.OrderHelper.sortOrders(filteredOrders, sortBy));
  }, [filteredOrders, sortBy]);

  const getMenuContent = () => {
    return {
      sections: [
        {
          items: (
            Object.values(
              DemmiFS.OrderHelper.SORT_BY,
            ) as Array<DemmiFS.OrderHelper.SORT_BY>
          ).map(c => ({
            id: c,
            text: c,
            item: c,
          })),
        },
      ],
    };
  };

  const onClick = (i: number) => {
    const order = filteredOrders[i];
    if (order) {
      setSearchParams({ [SearchParams.ORDER_ID]: order.docID });
      setSelectedOrder(order);
    }
  };

  const sidebarButtons = sortedOrders.map((order, i) => (
    <OrderSidebarButton
      active={selectedOrder?.docID === order.docID}
      order={order}
      key={i}
    />
  ));

  const sidebarTitleButtons = [
    <Menu<DemmiFS.OrderHelper.SORT_BY>
      content={getMenuContent()}
      triggerEl={<MenuTriggerButton title="Sort By" text={sortBy} />}
      onClick={sortBy => setSortBy(sortBy)}
      key={0}
    />,
    <div style={{ marginLeft: 'auto' }} key={1}>
      <DemmiIconButton
        faIcon="fa-rotate-right"
        onClick={onClickRefresh}
        size={IconButtonSize.SMALL}
      />
    </div>,
  ];

  const filterOrders = (filter?: string) => {
    const organised = orders.reduce(
      (acc: DemmiFS.Order[], cur: DemmiFS.Order) => {
        if (
          filter &&
          !cur.collectionCode.toLowerCase().includes(filter.toLowerCase())
        )
          return acc;
        else return [...acc, cur];
      },
      [],
    );
    setFilteredOrders(organised);
  };

  return (
    <PageWithSidebar
      sidebar={{
        customButtons: sidebarButtons,
        titleButtons: sidebarTitleButtons,
        active: filteredOrders.findIndex(f => selectedOrder?.docID === f.docID),
        onClick: onClick,
        searchPlaceholder: 'Filter Orders',
        onSearch: filterOrders,
      }}
      title={
        selectedOrder ? `Order #${selectedOrder.orderID}` : 'No order selected'
      }>
      {selectedOrder && <OrderItem orderDocID={selectedOrder.docID} />}
    </PageWithSidebar>
  );
};

export default OrdersList;
