import React from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import { getDayTitleFromKey } from '@helpers/schedule.helper';
import { VendorMappedScheduleDay } from '@models/vendorMappedSchedule.model';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';
import SMCollectionScheduleDayPreviewTimeline from './_dayPreviewTimeline';

interface Props {
  day: DemmiFS.DemmiHelper.DayKeys;
  slotSchedule?: VendorMappedScheduleDay;
}

const SMCollectionSchedulePreviewViewDay: React.FC<Props> = ({
  day,
  slotSchedule,
}) => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-preview-view__day`;

  const slotTimes = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__day-time`}>
      <div className={`${CSSBlock}__day-section`}>
        <div className={`${CSSBlock}__day-section-title`}>Start Time</div>
        {DemmiHelpers.DemmiTime.timeToString(day.startTime)}
      </div>
      <div className={`${CSSBlock}__day-section`}>
        <div className={`${CSSBlock}__day-section-title`}>End Time</div>
        {DemmiHelpers.DemmiTime.timeToString(day.endTime)}
      </div>
    </div>
  );

  const slotDuration = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__day-section`}>
      <div className={`${CSSBlock}__day-section-title`}>Duration</div>
      {day.slotDuration} mins
    </div>
  );

  const slotCapacity = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__day-section`}>
      <div className={`${CSSBlock}__day-section-title`}>Capacity</div>
      {day.capacity}
    </div>
  );

  const breaksContainer = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__breaks-container`}>
      <div className={`${CSSBlock}__breaks-title`}>Scheduled Breaks</div>
      {day.breaks.map((breakSlot, i) => (
        <div className={`${CSSBlock}__break-row`} key={i}>
          <div className={`${CSSBlock}__break-item`}>
            <div className={`${CSSBlock}__break-title`}>Start</div>
            {DemmiHelpers.DemmiTime.timeToString(breakSlot.open)}
          </div>
          <div className={`${CSSBlock}__break-item`}>
            <div className={`${CSSBlock}__break-title`}>End</div>
            {DemmiHelpers.DemmiTime.timeToString(breakSlot.close)}
          </div>
        </div>
      ))}
    </div>
  );

  const slotsContainer = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__days-container`}>
      <div className={`${CSSBlock}__day`}>
        {slotTimes(day)}
        {slotDuration(day)}
        {slotCapacity(day)}
      </div>
      {day.breaks.length > 0 && breaksContainer(day)}
      <SMCollectionScheduleDayPreviewTimeline
        isOpen={!!slotSchedule?.enabled}
        day={day}
      />
    </div>
  );

  return (
    <DemmiCard className={`${CSSBlock}`} noPadding>
      <div className={`${CSSBlock}__title`}>
        {getDayTitleFromKey(day)}
        {!slotSchedule?.enabled && (
          <div className={`${CSSBlock}__title-badge`}>Disabled</div>
        )}
      </div>
      {slotSchedule?.enabled && slotsContainer(slotSchedule)}
    </DemmiCard>
  );
};
export default SMCollectionSchedulePreviewViewDay;
