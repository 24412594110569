import { Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { NetworkService } from '@core/services/networkService/networkService';
import {
  CalculatedDayDetail,
  calculateOpenHoursDay,
} from '@helpers/timeslots.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import DashboardSection from '../DashboardSection';
import WidgetIncomingScheduleSlot from './WidgetIncomingScheduleSlot';
import {
  WidgetIncomingScheduleTimeSlot,
  getTimes,
  getTodaysHours,
  widgetIncomingScheduleTimeInterval,
} from './_helper.ts';

interface Props {
  vendor: DemmiFS.Vendor;
}

const WidgetIncomingSchedule: React.FC<Props> = ({ vendor }) => {
  const CSSBlock = 'widget-incoming-schedule';
  const hours = getTodaysHours(vendor.shop.openingHours);

  const [isLoading, setIsLoading] = useState(true);
  const [todaysOrders, setOrders] = useState<DemmiFS.Order[]>([]);
  const [slots, setSlots] = useState<WidgetIncomingScheduleTimeSlot[]>([]);

  const timeIndicatorRef = useRef<HTMLDivElement>(null);
  const [timeIndicator, setTimeIndicator] = useState<number>(600);
  const slotHeight = 7;
  const slotMargin = 0.4;

  const [calculatedOpenHours, setCalculatedOpenHours] =
    useState<CalculatedDayDetail>();

  useEffect(() => {
    if (hours)
      setCalculatedOpenHours(
        calculateOpenHoursDay(hours.intervals, hours.breaks ?? []),
      );
  }, [hours]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    if (vendor) {
      const ordersCallback = (o: DemmiFS.Order[]) => {
        const orders = DemmiFS.OrderHelper.getIncomingOrders(
          vendor.docID,
          o,
        ).filter(o => {
          const orderDate = o.vendors[vendor.docID].collectionDate;
          if (!orderDate) return false;
          const today = new Date();
          return (
            today.getFullYear() === orderDate.year &&
            today.getMonth() === orderDate.month - 1 &&
            today.getDate() === orderDate.day
          );
        });
        setOrders(DemmiFS.OrderHelper.sortByCollectionDate(orders));
        setIsLoading(false);
      };
      NetworkService.Orders.listenToOrders(vendor.docID, ordersCallback).then(
        u => (snapshotListener = u),
      );
    }

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [vendor]);

  useEffect(() => {
    setSlots(getTimes(vendor.docID, calculatedOpenHours, todaysOrders));
  }, [todaysOrders]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const minutes = now.getHours() * 60 + now.getMinutes();
      setTimeIndicator(minutes);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    timeIndicatorRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [timeIndicatorRef, timeIndicator]);

  return (
    <DashboardSection
      className={CSSBlock}
      isLoading={isLoading}
      title="Collection Schedule">
      <div className={`${CSSBlock}__list`}>
        {slots.map((slot, i) => (
          <WidgetIncomingScheduleSlot
            slot={slot}
            key={i}
            hasPassed={i < 5}
            vendor={vendor}
          />
        ))}

        <div
          className={`${CSSBlock}__time-indicator`}
          ref={timeIndicatorRef}
          style={{
            top: `${(timeIndicator / widgetIncomingScheduleTimeInterval) * (slotHeight + slotMargin)}rem`,
          }}></div>
      </div>
      {todaysOrders.length === 0 && (
        <div className={`${CSSBlock}__message`}>
          <div className={`${CSSBlock}__message-text`}>
            {!calculatedOpenHours || calculatedOpenHours.totalOpenHours === 0
              ? `Your shop is closed\nfor the day.`
              : 'No orders today'}
          </div>
        </div>
      )}
    </DashboardSection>
  );
};

export default WidgetIncomingSchedule;
