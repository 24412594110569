import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { DemmiFS } from '@subhanhabib/demmilib';

export interface Props {
  CSSBlock: string;
  product: DemmiFS.Product;
  onClick?: (e: React.MouseEvent) => void;
}

const InventorySidebarButton: React.FC<Props> = ({
  CSSBlock: parentCSS,
  product,
  onClick,
}) => {
  const CSSBlock = `${parentCSS}-sidebar-button`;

  return (
    <div
      className={`
          ${CSSBlock} ${CSSBlock}--inventory 
          ${product.isArchived ? `${CSSBlock}--archived` : ''}`}
      onClick={onClick}>
      <div className={`${CSSBlock}__image`}>
        {product.images.length > 0 && (
          <AsyncImage src={product.images[0].thumbnail} localLoadingIndicator />
        )}
        {product.isArchived && (
          <div className={`${CSSBlock}__status`}>Archived</div>
        )}
      </div>
      <span className={`${CSSBlock}__text`}>{product.name}</span>
    </div>
  );
};

export default InventorySidebarButton;
