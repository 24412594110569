import { FSAnalytics } from './analytics/analytics';
import { FSCustomer } from './customer/customer';
import { FSDemmi } from './demmi/demmi';
import { FSDemmiSupport } from './demmiSupport/demmiSupport';
import { FSCoupons } from './functions/_coupons';
import { FSProduct } from './functions/_product';
import { FSProductVariants } from './functions/_productVariants';
import { FSVendors } from './functions/_vendors';
import { FSOrders } from './orders/orders';
import { FSRefundRequests } from './refundRequests/refundRequests';
import { FSReviews } from './reviews/reviews';
import { FSVendorTimeSlotSchedules } from './vendorTimeSlotSchedules/vendorTimeSlotSchedules';
import { FSVendorTimeSlots } from './vendorTimeSlots/vendorTimeSlots';

export class NetworkService {
  static Analytics = {
    getStartDate: FSAnalytics._getStartDate,
    listenToTotalOrderCount: FSAnalytics._listenToTotalOrderCount,
    listenToTotalProductsSold: FSAnalytics._listenToTotalProductsSold,
  };
  static Coupons = {
    createCoupon: FSCoupons._createCoupon,
    checkCodeAvailability: FSCoupons._checkCodeAvailability,
    getCoupons: FSCoupons._getCoupons,
    deleteCoupon: FSCoupons._deleteCoupon,
    updateCouponDates: FSCoupons._updateCouponDates,
  };
  static Customer = FSCustomer;
  static Demmi = {
    populate: FSDemmi._populate,
  };
  static DemmiSupport = {
    listenToDemmiSupport: FSDemmiSupport._listenToDemmiSupport,
    listenToDemmiSupportChatMessages:
      FSDemmiSupport._listenToDemmiSupportChatMessages,
    getLastDemmiSupportMessage: FSDemmiSupport._getLastDemmiSupportMessage,
    getDemmiSupportChat: FSDemmiSupport._getDemmiSupportChat,
    sendDemmiSupportMessage: FSDemmiSupport._sendDemmiSupportMessage,
    createDemmiSupportChat: FSDemmiSupport._createDemmiSupportChat,
  };
  static Orders = {
    listenToOrders: FSOrders._listenToOrders,
    getOrders: FSOrders._getOrders,
    getOrdersWithRefunds: FSOrders._getOrdersWithRefunds,
    listenToOrder: FSOrders._listenToOrder,
    listenToOrderReview: FSOrders._listenToOrderReview,
    updateOrderReviewResponse: FSOrders._updateOrderReviewResponse,
    updateOrderTimeline: FSOrders._updateOrderTimeline,
    updateOrderStatus: FSOrders._updateOrderStatus,
  };
  static Product = {
    createProduct: FSProduct._createProduct,
    getProducts: FSProduct._getProducts,
    getProduct: FSProduct._getProduct,
    updateProduct: FSProduct._updateProduct,
    updateProductPublished: FSProduct._updateProductPublished,
    updateProductImages: FSProduct._updateProductImages,
    checkProductImageExists: FSProduct._checkProductImageExists,
    deleteProduct: FSProduct._deleteProduct,
    updateProductArchived: FSProduct._updateProductArchived,
  };
  static ProductVariants = {
    createProductVariant: FSProductVariants._createProductVariant,
    getProductVariants: FSProductVariants._getProductVariants,
    updateProductVariant: FSProductVariants._updateProductVariant,
    deleteProductVariant: FSProductVariants._deleteProductVariant,
  };
  static RefundRequests = {
    listenToRefundRequests: FSRefundRequests._listenToRefundRequests,
    listenToRefundRequest: FSRefundRequests._listenToRefundRequest,
    hasRefundRequest: FSRefundRequests._hasRefundRequest,
    updateRefundRequest: FSRefundRequests._updateRefundRequest,
    createRefundRequest: FSRefundRequests._createRefundRequest,
  };
  static Reviews = {
    listenToReviews: FSReviews._listenToReviews,
    getAllReviews: FSReviews._getAllReviews,
  };

  // Vendor
  static Vendors = {
    getVendorUser: FSVendors._getVendorUser,
    getVendor: FSVendors._getVendor,
    updateVendorDetails: FSVendors._updateVendorDetails,
    updateVendorTheme: FSVendors._updateVendorTheme,
    updateVendorHours: FSVendors._updateVendorHours,
    updateVendorAddress: FSVendors._updateVendorAddress,
    checkVendorImageExists: FSVendors._checkVendorImageExists,
    updateVendorImages: FSVendors._updateVendorImages,
  };
  static VendorTimeSlots = {
    getSlots: FSVendorTimeSlots._getSlots,
  };
  static VendorTimeSlotSchedule = {
    getSchedule: FSVendorTimeSlotSchedules._getSchedule,
    getAllSchedules: FSVendorTimeSlotSchedules._getAllSchedules,
    createSchedule: FSVendorTimeSlotSchedules._createSchedule,
    doesDateConflict: FSVendorTimeSlotSchedules._doesDateConflict,
    updateSchedule: FSVendorTimeSlotSchedules._updateSchedule,
    deleteSchedule: FSVendorTimeSlotSchedules._deleteSchedule,
  };
  static VendorTimeSlotExceptions = {
    // getExceptions: FSVendorTimeSlotExceptions._getExceptions,
    // createException: FSVendorTimeSlotExceptions._createException,
    // updateException: FSVendorTimeSlotExceptions._updateException,
    // deleteException: FSVendorTimeSlotExceptions._deleteException,
  };
}
