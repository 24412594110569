import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToSlot = (
  doc: QueryDocumentSnapshot<DemmiFS.FSVendorTimeSlotSchedule>,
): DemmiFS.VendorTimeSlotSchedule => {
  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSVendorTimeSlotSchedule),
  };
};
