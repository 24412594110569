import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import ModalBoolean from '@components/modals/ModalBoolean';
import ModalPublish from '@components/modals/ModalPublish';
import { NetworkService } from '@core/services/networkService/networkService';
import DemmiButton from '@demmi-ui/Button';
import { demmiRequest } from '@helpers/app.helper';
import {
  isProductDetailsValid,
  productHasChanges,
} from '@helpers/products.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import ProductCategory from './partials/Category';
import Description from './partials/Description';
import Images from './partials/Images';
import Tags from './partials/Tags';

interface Props {
  product: DemmiFS.Product;
  firestoreDemmi: DemmiFS.FSDemmiConstants;
  onChangeArchived: (id: string) => void;
}

const InventoryProductDetails: React.FC<Props> = ({
  product,
  firestoreDemmi,
  onChangeArchived: onArchived,
}) => {
  const CSSBlock = 'product-details';

  const [coreProduct, setCoreProduct] = useState<DemmiFS.Product>();
  const [isPublished, setIsPublished] = useState<boolean>();
  const [isArchived, setIsArchived] = useState<boolean>();
  const [name, setName] = useState('');
  const [categoryID, setCategoryID] = useState<string | undefined>();
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState<DemmiFS.DemmiImageResource[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  const [detectedChanges, setDetectedChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const setValues = (product: DemmiFS.Product) => {
    setIsPublished(!!product.isPublished);
    setIsArchived(!!product.isArchived);
    setName(product.name);
    setCategoryID(product.categoryID ?? '');
    setShortDescription(product.shortDescription);
    setDescription(product.longDescription.join('\n'));
    setImages(product.images);
    setTags(product.tags);
  };

  useEffect(() => {
    demmiRequest(NetworkService.Product.getProduct(product.docID)).then(p => {
      setCoreProduct(p);
      if (p) setValues(p);
    });
  }, [product]);

  useEffect(() => {
    setDetectedChanges(
      !coreProduct ||
        productHasChanges(coreProduct, {
          name,
          categoryID: categoryID ?? '',
          shortDescription,
          longDescription: description.split('\n'),
          images,
          tags,
        }),
    );
    setIsValid(
      isProductDetailsValid({
        name,
        categoryID: categoryID ?? '',
        shortDescription,
        longDescription: description.split('\n'),
        images,
        tags,
      }),
    );
  }, [
    name,
    categoryID,
    shortDescription,
    description,
    images,
    tags,
    coreProduct,
  ]);

  const discardChanges = () => {
    if (coreProduct) setValues(coreProduct);
  };

  const saveChanges = async () => {
    if (
      coreProduct &&
      name.length > 0 &&
      description.length > 0 &&
      categoryID
    ) {
      const product: DemmiFS.Product = {
        docID: coreProduct.docID,
        vendorID: coreProduct.vendorID,
        name,
        categoryID,
        shortDescription,
        longDescription: description
          .split('\n')
          .filter(value => value.length > 0),
        images,
        tags,
        variants: [],
        isPublished: false,
        isArchived: false,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      demmiRequest(NetworkService.Product.updateProduct(product)).then(() =>
        setCoreProduct(product),
      );
    }
  };

  const onChangePublished = (isPublished: boolean) => {
    if (product)
      return demmiRequest(
        NetworkService.Product.updateProductPublished(
          product.docID,
          isPublished,
        ),
      )
        .then(() => {
          setIsPublished(isPublished);
          return true;
        })
        .catch(() => false);
  };

  const onChangeArchived = async (isArchived: boolean) => {
    if (product) {
      await demmiRequest(
        NetworkService.Product.updateProductArchived(product.docID, isArchived),
      )
        .then(() => {
          setIsArchived(isArchived);
          return true;
        })
        .catch(() => false);
      onArchived(product.docID);
    }
  };

  return (
    <div className={CSSBlock}>
      <div
        className={`${CSSBlock}__status ${
          isArchived
            ? `${CSSBlock}__status--archived`
            : isPublished
              ? `${CSSBlock}__status--published`
              : ''
        }`}>
        <div className={`${CSSBlock}__status-text`}>
          {isArchived
            ? 'Archived'
            : isPublished
              ? 'Published'
              : 'Not Published'}
        </div>
      </div>
      <div className={`${CSSBlock}__details`}>
        <Description
          CSSBlock={CSSBlock}
          name={name}
          setName={setName}
          shortDescription={shortDescription}
          setShortDescription={setShortDescription}
          description={description}
          setDescription={setDescription}
        />
        <ProductCategory
          categoryID={categoryID}
          setCategoryID={setCategoryID}
        />
        <Images images={images} setImages={setImages} product={product} />
        <Tags
          productTags={firestoreDemmi.product_tags ?? []}
          tags={tags}
          setTags={setTags}
        />
      </div>
      <div className={`${CSSBlock}__buttons-row`}>
        <ModalBoolean
          titleText={`Are you sure you want to
                ${isArchived ? 'unarchive' : 'archive'}`}
          itemText={name}
          onClose={() => {}}
          onConfirm={() => onChangeArchived(!isArchived)}
          triggerButtonText={isArchived ? 'Unarchive' : 'Archive'}
          triggerButtonIcon={'fa-box-archive'}
          triggerButtonVariant={isArchived ? 'save' : 'delete'}
        />
        <DemmiButton
          text={'Discard Changes'}
          faIcon="fa-ban"
          disabled={!detectedChanges}
          onClick={() => discardChanges()}
        />
        <DemmiButton
          text={'Save Changes'}
          faIcon="fa-regular fa-floppy-disk"
          disabled={!detectedChanges || !isValid}
          onClick={() => saveChanges()}
        />
        <ModalPublish
          isPublished={!!isPublished}
          onClose={() => {}}
          onConfirm={() => onChangePublished(!isPublished)}
        />
      </div>
    </div>
  );
};

export default InventoryProductDetails;
