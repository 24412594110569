import { VendorMappedScheduleDay } from '@models/vendorMappedSchedule.model';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

export type TimeSlotKeys = 'openSlots' | 'exceptionSlots';

export interface DayDetail {
  isOpen: boolean;
  openSlots: DemmiFS.VendorHelper.TimeInterval[];
  exceptionSlots: DemmiFS.VendorHelper.TimeInterval[];
}

export type DaysDetails = {
  [key in DemmiFS.DemmiHelper.DayKeys]: DayDetail;
};

/**
 *
 * For previewing the chosen day's opening hours
 *
 */

export interface CalculatedDayDetail {
  totalOpenHours: number;
  timeSlots: DemmiFS.VendorHelper.TimeInterval[];
}

// ----------------------------

export const calculateTotalHours = (
  slots: DemmiFS.VendorHelper.TimeInterval[],
): number => {
  return (
    slots.reduce((total, slot) => total - (slot.open - slot.close), 0) / 60
  );
};

export const isOpenOnDay = (dayHours: DemmiFS.OpeningHours): boolean =>
  calculateTotalHours(dayHours.intervals) > 0;

// Reduces overlapping intervals
const _flattenIntervals = (slots: DemmiFS.VendorHelper.TimeInterval[]) => {
  const merged: DemmiFS.VendorHelper.TimeInterval[] = [];
  slots.sort((a, b) => a.open - b.open);

  for (const slot of slots.filter(slot => slot.open < slot.close)) {
    if (merged.length === 0 || merged[merged.length - 1].close < slot.open) {
      merged.push(slot);
    } else {
      merged[merged.length - 1].close = Math.max(
        merged[merged.length - 1].close,
        slot.close,
      );
    }
  }
  return merged;
};

const _isOutOfBound = (
  openSlot: DemmiFS.VendorHelper.TimeInterval,
  exceptionSlot: DemmiFS.VendorHelper.TimeInterval,
) =>
  !(openSlot.open > exceptionSlot.close || openSlot.close < exceptionSlot.open);

const _removeOutOfBoundExceptions = (
  openSlot: DemmiFS.VendorHelper.TimeInterval,
  exceptionSlots: DemmiFS.VendorHelper.TimeInterval[],
): DemmiFS.VendorHelper.TimeInterval[] =>
  exceptionSlots.filter(exceptionSlot => {
    return !(
      openSlot.open > exceptionSlot.close || openSlot.close < exceptionSlot.open
    );
  });
const _isCompleteOverlap = (
  openSlot: DemmiFS.VendorHelper.TimeInterval,
  exceptionSlots: DemmiFS.VendorHelper.TimeInterval[],
): boolean =>
  exceptionSlots.some(exceptionSlot => {
    return (
      exceptionSlot.open <= openSlot.open &&
      exceptionSlot.close >= openSlot.close
    );
  });

/**
 * For each openSlot, check if overlap with exceptionSlot.
 * If yes, remove the overlap part from openSlot.
 */
const _subtractFromOpen = (
  openSlots: DemmiFS.VendorHelper.TimeInterval[],
  toSubtract: DemmiFS.VendorHelper.TimeInterval[],
): DemmiFS.VendorHelper.TimeInterval[] => {
  if (toSubtract.length === 0) return openSlots;

  return openSlots
    .map(openSlot => {
      const inBoundExceptions = _removeOutOfBoundExceptions(
        openSlot,
        toSubtract,
      );
      if (inBoundExceptions.length === 0) return openSlot;

      const exceptionSlot = inBoundExceptions[0];
      const remainingExceptions = inBoundExceptions.slice(1);

      const isOpenBeforeOpen = exceptionSlot.open <= openSlot.open;
      const isCloseAfterClose = exceptionSlot.close >= openSlot.close;
      const isOpenAfterOpen = exceptionSlot.open >= openSlot.open;
      const isCloseBeforeClose = exceptionSlot.close <= openSlot.close;

      if (isOpenAfterOpen && isCloseBeforeClose) {
        // Exception within bounds
        const newSlots = [
          {
            open: openSlot.open,
            close: exceptionSlot.open,
          },
          {
            open: exceptionSlot.close,
            close: openSlot.close,
          },
        ] as DemmiFS.VendorHelper.TimeInterval[];
        return newSlots.flatMap(slot =>
          _subtractFromOpen([slot], remainingExceptions),
        );
      } else if (isOpenBeforeOpen && isCloseBeforeClose) {
        // Exception overlaps lower
        const newSlot = {
          open: exceptionSlot.close,
          close: openSlot.close,
        } as DemmiFS.VendorHelper.TimeInterval;
        return _subtractFromOpen([newSlot], remainingExceptions);
      } else if (isOpenAfterOpen && isCloseAfterClose) {
        // Exception overlaps upper
        const newSlot = {
          open: openSlot.open,
          close: exceptionSlot.open,
        } as DemmiFS.VendorHelper.TimeInterval;
        return _subtractFromOpen([newSlot], remainingExceptions);
      } else {
        Logger({
          messages: ['Unhandled case in _subtractIntervals'],
          type: DemmiLogType.error,
        });
        return [openSlot];
      }
    })
    .flat();
};

const _subtractIntervals = (
  openSlots: DemmiFS.VendorHelper.TimeInterval[],
  exceptionSlots: DemmiFS.VendorHelper.TimeInterval[],
): DemmiFS.VendorHelper.TimeInterval[] => {
  const mapped = openSlots.map(openSlot => {
    const toSubtract = _removeOutOfBoundExceptions(openSlot, exceptionSlots);
    if (_isCompleteOverlap(openSlot, exceptionSlots)) return;
    if (toSubtract.length === 0) return openSlot;
    const toReturn = _subtractFromOpen([openSlot], toSubtract);
    return toReturn;
  });

  const filtered = mapped
    .flat()
    .reduce((acc: DemmiFS.VendorHelper.TimeInterval[], item) => {
      if (!item) return acc;
      return [...acc, ...(Array.isArray(item) ? item : [item])];
    }, []);
  return _flattenIntervals(filtered);
};

// Calculates for a single day
export const calculateOpenHoursDay = (
  openSlots: DemmiFS.VendorHelper.TimeInterval[],
  exceptionSlots: DemmiFS.VendorHelper.TimeInterval[],
): CalculatedDayDetail => {
  const timeSlots = _subtractIntervals(openSlots, exceptionSlots);
  const totalOpenHours = calculateTotalHours(timeSlots);
  return {
    totalOpenHours,
    timeSlots,
  };
};

export const calculateScheduleDay = (
  day: VendorMappedScheduleDay,
): DemmiFS.VendorHelper.TimeInterval[] => {
  return _subtractIntervals(
    [{ open: day.startTime, close: day.endTime }],
    day.breaks,
  );
};
