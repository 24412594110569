import { format } from 'date-fns';
import React from 'react';

import DemmiButton, { DemmiButtonSize } from '@demmi-ui/Button';
import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';
import { VendorMappedSchedule } from '@models/vendorMappedSchedule.model';

interface Props {
  CSSBlock: string;
  schedules: VendorMappedSchedule[];
  onClickPreview: (docID: string) => void;
  onClickEdit: (docID: string) => void;
  onClickAdd: () => void;
}

const SMCollectionScheduleList: React.FC<Props> = ({
  CSSBlock: parentBlock,
  schedules,
  onClickPreview,
  onClickEdit,
  onClickAdd,
}) => {
  const CSSBlock = `${parentBlock}-list`;

  const scheduleItem = (schedule: VendorMappedSchedule, expired: boolean) => (
    <div
      className={`${CSSBlock}__item ${expired ? `${CSSBlock}__item--expired` : ``}`}
      key={schedule.scheduleID}>
      <div className={`${CSSBlock}__item-dates`}>
        <div className={`${CSSBlock}__item-date-item`}>
          <div className={`${CSSBlock}__item-date-title`}>From</div>
          <div className={`${CSSBlock}__item-date`}>
            {format(schedule.startDate!, 'dd MMM yyyy')}
          </div>
        </div>
        <div className={`${CSSBlock}__item-date-item`}>
          <div className={`${CSSBlock}__item-date-title`}>To</div>
          <div className={`${CSSBlock}__item-date`}>
            {format(schedule.endDate!, 'dd MMM yyyy')}
          </div>
        </div>
        {expired ? (
          <div className={`${CSSBlock}__item-badge`}>Expired</div>
        ) : (
          <div className={`${CSSBlock}__item-controls`}>
            <DemmiIconButton
              icon={faSolid.faPen}
              onClick={() => onClickEdit(schedule.scheduleID)}
              size={IconButtonSize.SMALL}
            />
            <DemmiIconButton
              icon={faSolid.faEye}
              onClick={() => onClickPreview(schedule.scheduleID)}
              size={IconButtonSize.SMALL}
            />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Your Schedules</div>
      {schedules.map(s => scheduleItem(s, s.endDate! < new Date()))}
      <DemmiButton
        size={DemmiButtonSize.S}
        text="Add New Schedule"
        onClick={onClickAdd}
      />
    </div>
  );
};

export default SMCollectionScheduleList;
