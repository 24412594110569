import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  product: DemmiFS.FSOrderProduct;
  size?: 'S' | 'M';
  listView?: boolean;
}

const OrderProductItem: React.FC<Props> = ({
  product,
  size = 'M',
  listView = false,
}) => {
  const CSSBlock = 'order-product-item';
  return (
    <div
      className={`${CSSBlock} ${CSSBlock}--${size} ${
        listView ? `${CSSBlock}--list-view` : ''
      }`}>
      <div className={`${CSSBlock}__image`}>
        <AsyncImage src={product.image} localLoadingIndicator />
      </div>
      <div className={`${CSSBlock}__info`}>
        <div className={`${CSSBlock}__name`}>{product.name}</div>
        <div className={`${CSSBlock}__variant`}>{product.variantName}</div>
        <div className={`${CSSBlock}__quantity`}>
          {product.quantity}{' '}
          <span>
            {DemmiFS.UnitsHelper.getWeightUnitText(product.variantUnit)}
          </span>
        </div>
      </div>
      <div className={`${CSSBlock}__cost`}>
        {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(
          product.currencyCode,
        )}
        {Number(product.price).toFixed(2)}
      </div>
    </div>
  );
};

export default OrderProductItem;
