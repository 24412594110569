import { useEffect, useState } from 'react';

import { NetworkService } from '@core/services/networkService/networkService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { demmiRequest } from '@helpers/app.helper';
import { VendorMappedSchedule } from '@models/vendorMappedSchedule.model';

import { useAppSelector } from './appHooks';

const useFetchCollectionSchedules = () => {
  const vendor = useAppSelector(selectVendor);
  const [schedules, setSchedules] = useState<VendorMappedSchedule[]>([]);

  useEffect(() => {
    if (vendor)
      demmiRequest(
        NetworkService.VendorTimeSlotSchedule.getAllSchedules(vendor.docID),
      ).then(s => setSchedules(s));
  }, [vendor]);

  return schedules;
};

export default useFetchCollectionSchedules;
