import React from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import DemmiToggle from '@demmi-ui/Toggle';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import {
  getDayTitleFromKey,
  getTimeSlotDuration,
} from '@helpers/schedule.helper';
import { VendorMappedScheduleDay } from '@models/vendorMappedSchedule.model';
import { DemmiFS } from '@subhanhabib/demmilib';

import InputTime from '../../../../demmi-ui/InputTime/InputTime';
import { CSSBLOCK_SM_COLLECTION_SCHEDULE } from '../CollectionSchedule';
import { COLLECTION_SCHEDULE_VALIDATION_ERRORS } from '../Create/_helper';
import SMCollectionScheduleDayPreviewTimeline from './_dayPreviewTimeline';

interface Props {
  day: DemmiFS.DemmiHelper.DayKeys;
  slotSchedule?: VendorMappedScheduleDay;
  toggleDayOpen: (open: boolean) => void;
  onChangeTime: (type: 'start' | 'end') => (val: number) => void;
  onChangeDuration: (
    val: DemmiFS.VendorHelper.TimeSlotScheduleDuration,
  ) => void;
  onChangeCapacity: (val: number) => void;
  onAddBreak: () => void;
  onRemoveBreak: (i: number) => void;
  onUpdateBreak: (
    i: number,
    key: keyof DemmiFS.VendorHelper.TimeInterval,
    val: number,
  ) => void;
  isValid: COLLECTION_SCHEDULE_VALIDATION_ERRORS | undefined;
  existsInRange?: boolean;
}

const SMCollectionScheduleDay: React.FC<Props> = ({
  day,
  slotSchedule,
  toggleDayOpen,
  onChangeTime,
  onChangeDuration,
  onChangeCapacity,
  onAddBreak,
  onRemoveBreak,
  onUpdateBreak,
  isValid,
  existsInRange,
}) => {
  const CSSBlock = `${CSSBLOCK_SM_COLLECTION_SCHEDULE}-editing-view__day`;

  const getDurationMenuContent = (
    durations: DemmiFS.VendorHelper.TimeSlotScheduleDuration[],
  ) => {
    return {
      sections: [
        {
          items: Object.entries(durations).map(([val, duration]) => ({
            id: val,
            text: getTimeSlotDuration(duration),
            item: duration,
          })),
        },
      ],
    };
  };

  const slotTimes = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__day-time`}>
      <div className={`${CSSBlock}__day-section`}>
        <div className={`${CSSBlock}__day-section-title`}>Start Time</div>
        <InputTime
          value={day.startTime}
          onChange={onChangeTime('start')}
          inline
        />
      </div>
      <div className={`${CSSBlock}__day-section`}>
        <div className={`${CSSBlock}__day-section-title`}>End Time</div>
        <InputTime value={day.endTime} onChange={onChangeTime('end')} inline />
      </div>
    </div>
  );

  const slotDuration = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__day-section`}>
      <div className={`${CSSBlock}__day-section-title`}>Duration</div>
      <Menu<DemmiFS.VendorHelper.TimeSlotScheduleDuration>
        content={getDurationMenuContent([
          DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_15,
          DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_30,
          DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_60,
        ])}
        triggerEl={<MenuTriggerButton text={day.slotDuration.toString()} />}
        onClick={onChangeDuration}
      />
    </div>
  );

  const slotCapacity = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__day-section`}>
      <div className={`${CSSBlock}__day-section-title`}>Capacity</div>
      <DemmiInput
        type="number"
        value={day.capacity.toString()}
        onChangeValue={val => onChangeCapacity(Number(val))}
      />
    </div>
  );

  const breaksContainer = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__breaks-container`}>
      <div className={`${CSSBlock}__breaks-title`}>Schedule Breaks</div>
      {day.breaks.map((breakSlot, i) => (
        <div className={`${CSSBlock}__break-row`} key={i}>
          <div className={`${CSSBlock}__break-item`}>
            <div className={`${CSSBlock}__break-title`}>Start Time</div>
            <InputTime
              value={breakSlot.open}
              onChange={val => onUpdateBreak(i, 'open', val)}
              inline
            />
          </div>
          <div className={`${CSSBlock}__break-item`}>
            <div className={`${CSSBlock}__break-title`}>End Time</div>
            <InputTime
              value={breakSlot.close}
              onChange={val => onUpdateBreak(i, 'close', val)}
              inline
            />
          </div>
          <DemmiIconButton
            icon={faLight.faTrashCan}
            onClick={() => onRemoveBreak(i)}
          />
        </div>
      ))}
      <DemmiButton text="Add Break" onClick={onAddBreak} />
    </div>
  );

  const slotsContainer = (day: VendorMappedScheduleDay) => (
    <div className={`${CSSBlock}__days-container`}>
      <div className={`${CSSBlock}__day`}>
        {slotTimes(day)}
        {slotDuration(day)}
        {slotCapacity(day)}
      </div>
      {breaksContainer(day)}
      <SMCollectionScheduleDayPreviewTimeline
        isOpen={!!slotSchedule?.enabled}
        day={day}
      />
    </div>
  );

  return (
    <DemmiCard className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>
        {getDayTitleFromKey(day)}
        {!existsInRange ? (
          <div className={`${CSSBlock}__badge`}>Excluded from dates</div>
        ) : (
          <div className={`${CSSBlock}__toggle`}>
            <DemmiToggle
              text={slotSchedule?.enabled ? 'Enabled' : 'Disabled'}
              onToggle={toggleDayOpen}
              isActive={slotSchedule?.enabled}
            />
          </div>
        )}
      </div>
      {slotSchedule?.enabled && existsInRange && slotsContainer(slotSchedule)}
      {isValid && (
        <div className={`${CSSBlock}__error`}>Error in schedule: {isValid}</div>
      )}
    </DemmiCard>
  );
};
export default SMCollectionScheduleDay;
