import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';

import ModalCreateProduct from '@components/modals/CreateProduct/ModalCreateProduct';
import PageWithSidebar from '@components/page/PageWithSidebar';
import { SidebarCustomSection } from '@components/sidebar/_helper';
import { useFirestoreDemmi } from '@core/contexts/FirestoreDemmiContext';
import { useAppSelector } from '@core/hooks/appHooks';
import { NetworkService } from '@core/services/networkService/networkService';
import { SearchParams } from '@core/services/routingService';
import { selectVendor } from '@core/store/appSlice/appSlice';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { demmiRequest } from '@helpers/app.helper';
import { CSSVARS, setCSSVar } from '@helpers/theme.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

import { organiseProducts } from './_helper';
import InventorySidebarButton from './_sidebarButton';
import InventoryViewDefault from './_viewDefault';
import InventoryViewWithPreview from './_viewWithPreview';

interface ProductCategory {
  icon: IconDefinition;
  categoryName: string;
  products: DemmiFS.Product[];
}

interface CategorisedProducts {
  [key: string]: ProductCategory;
}

const Inventory: React.FC = () => {
  const CSSBlock = 'inventory';
  const firestoreDemmi = useFirestoreDemmi();
  const [searchParams, setSearchParams] = useSearchParams();
  const vendor = useAppSelector(selectVendor);
  const [products, setProducts] = useState<DemmiFS.Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<CategorisedProducts>(
    {},
  );
  const [selectedProduct, setSelectedProduct] = useState<
    DemmiFS.Product | undefined
  >();
  const [livePreview, setLivePreview] = useState(false);

  useEffect(() => {
    setProducts([]);
    if (vendor) {
      setCSSVar(CSSVARS.PHONE_PREVIEW_HUE, vendor.themeHue.toString());
      refreshProducts().then(products => {
        if (products && searchParams.get(SearchParams.PRODUCT_ID)) {
          setSelectedProduct(
            products.find(
              p => p.docID === searchParams.get(SearchParams.PRODUCT_ID),
            ),
          );
        }
      });
    }
  }, [vendor]);

  useEffect(() => {
    filterProducts();
  }, [products]);

  const refreshProducts = (id?: string) => {
    if (vendor) {
      return demmiRequest(
        NetworkService.Product.getProducts(vendor.docID),
      ).then(products => {
        setProducts(products);
        setSelectedProduct(id ? products.find(p => p.docID === id) : undefined);
        return products;
      });
    } else return Promise.resolve();
  };

  const onCreated = async (id: string) => {
    refreshProducts(id);
  };

  const onClickRefresh = () => {
    refreshProducts();
  };

  const onClickProduct = (id: string) => {
    setSelectedProduct(undefined);
    setSearchParams({ [SearchParams.PRODUCT_ID]: id });
    const product = products.find(p => p.docID === id);
    if (product) setSelectedProduct(product);
    setTimeout(() => {}, 50);
  };

  // const onDelete = (id: string) => {
  //   demmiRequest(deleteProduct(id)).then(() => {
  //     setProducts([]);
  //     if (vendor)
  //       demmiRequest(getProducts(vendor.docID)).then(products => {
  //         setProducts(products);
  //         setSelectedProduct(undefined);
  //       });
  //   });
  // };

  const onChangeArchived = (id: string) => {
    refreshProducts(id);
  };

  const filterProducts = (filter?: string) => {
    setFilteredProducts(organiseProducts(products, firestoreDemmi, filter));
  };

  const sidebarButtons: SidebarCustomSection[] = Object.values(
    filteredProducts,
  ).map(
    category =>
      ({
        sectionTitle: category.categoryName,
        sectionIcon: category.icon,
        buttons: category.products.map((product, i) => ({
          id: product.docID,
          element: (
            <InventorySidebarButton
              CSSBlock={CSSBlock}
              product={product}
              key={i}
            />
          ),
        })),
      }) as SidebarCustomSection,
  );

  const sidebarTitleButtons = [
    { faIcon: 'fa-rotate-right', onClick: onClickRefresh },
    <ModalCreateProduct onClose={() => {}} onConfirm={onCreated} />,
    {
      faIcon: 'fa-mobile-notch',
      onClick: () => setLivePreview(!livePreview),
    },
  ];

  return (
    <PageWithSidebar
      sidebar={{
        title: 'Inventory',
        titleButtons: sidebarTitleButtons,
        customSectionedButtons: sidebarButtons,
        active: selectedProduct?.docID,
        onClick: (id: string) => onClickProduct(id),
        searchPlaceholder: 'Filter reviews by title',
        onSearch: filterProducts,
      }}
      title={selectedProduct ? selectedProduct.name : 'Inventory'}>
      <div
        className={`${CSSBlock} ${
          livePreview && selectedProduct ? `${CSSBlock}--live-preview` : ''
        }`}>
        <div className={`${CSSBlock}__details`}>
          {firestoreDemmi &&
            selectedProduct &&
            (livePreview ? (
              <InventoryViewWithPreview
                product={selectedProduct}
                firestoreDemmi={firestoreDemmi}
                onChangeArchived={onChangeArchived}
              />
            ) : (
              <InventoryViewDefault
                product={selectedProduct}
                firestoreDemmi={firestoreDemmi}
                onChangeArchived={onChangeArchived}
              />
            ))}
        </div>
      </div>
    </PageWithSidebar>
  );
};

export default Inventory;
