import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PageWithTabs from '@components/page/PageWithTabs';
import { PageTabBase } from '@components/page/_helper';
import { useAppDispatch } from '@core/hooks/appHooks';
import {
  RoutingViews,
  getViewFromPath,
  routingPaths,
} from '@core/services/routingService';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import { navigateTransitioned } from '@helpers/app.helper';

const Orders: React.FC = () => {
  const view = getViewFromPath(location.pathname, false, {
    exclude: [RoutingViews.ORDERS],
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tabs: PageTabBase[] = [
    {
      id: RoutingViews.ORDERS_INCOMING,
      active: view === RoutingViews.ORDERS_INCOMING,
      icon: faLight.faCircleDown,
      text: 'Incoming Orders',
      onClick: () => {
        navigateTransitioned({
          dispatch,
          navigate,
          to: routingPaths[RoutingViews.ORDERS_INCOMING],
        });
      },
    },
    {
      id: RoutingViews.ORDERS_COMPLETED,
      active: view === RoutingViews.ORDERS_COMPLETED,
      icon: faLight.faCheck,
      text: 'Completed Orders',
      onClick: () => {
        navigateTransitioned({
          dispatch,
          navigate,
          to: routingPaths[RoutingViews.ORDERS_COMPLETED],
        });
      },
    },
    {
      id: RoutingViews.ORDERS_REFUNDS,
      active: view === RoutingViews.ORDERS_REFUNDS,
      icon: faLight.faMoneyBillTransfer,
      text: 'Orders with Refunds',
      onClick: () => {
        navigateTransitioned({
          dispatch,
          navigate,
          to: routingPaths[RoutingViews.ORDERS_REFUNDS],
        });
      },
    },
    {
      id: RoutingViews.ORDERS_ALL,
      active: view === RoutingViews.ORDERS_ALL,
      icon: faLight.faList,
      text: 'All Orders',
      onClick: () => {
        navigateTransitioned({
          dispatch,
          navigate,
          to: routingPaths[RoutingViews.ORDERS_ALL],
        });
      },
    },
  ];

  return (
    <PageWithTabs tabs={tabs}>
      <Outlet />
    </PageWithTabs>
  );
};

export default Orders;
