import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutingViews, routingPaths } from '@core/services/routingService';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  vendorID: string;
  order: DemmiFS.Order;
}

const WidgetIncomingScheduleSlotItem: React.FC<Props> = ({
  vendorID,
  order,
}) => {
  const CSSBlock = 'widget-incoming-schedule-slot';
  const navigate = useNavigate();

  return (
    <div
      className={`${CSSBlock}__order`}
      onClick={() =>
        navigate(
          `${routingPaths[RoutingViews.ORDERS_ALL]}?orderID=${order.docID}`,
          { replace: true },
        )
      }>
      <div className={`${CSSBlock}__order-status`}>
        {DemmiFS.OrderStatusHelper.getStatusFromOrder(vendorID, order)}
      </div>
      <div className={`${CSSBlock}__order-id`}># {order.orderID}</div>
      {order.customer && (
        <div className={`${CSSBlock}__order-customer`}>
          {order.customer.title} {order.customer.firstName}{' '}
          {order.customer.lastName}
        </div>
      )}
    </div>
  );
};

export default WidgetIncomingScheduleSlotItem;
