import { DateTime } from 'luxon';
import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

export interface Props {
  CSSBlock: string;
  coupon: DemmiFS.Coupon;
  active?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const CouponSidebarButton: React.FC<Props> = ({
  CSSBlock: parentCSS,
  coupon,
  active,
  onClick,
}) => {
  const CSSBlock = `${parentCSS}-sidebar-button`;

  return (
    <div
      className={`${CSSBlock}  ${active ? `${CSSBlock}--active` : ``}`}
      onClick={onClick}>
      <div className={`${CSSBlock}__type`}>{coupon.type}</div>
      <div className={`${CSSBlock}__code`}>{coupon.docID}</div>
      <div className={`${CSSBlock}__expiration`}>
        Start date:{' '}
        {coupon.startDate
          ? DateTime.fromJSDate(
              DemmiFS.getDateFromFirestore(coupon.startDate)!,
            ).toLocaleString()
          : 'No start date'}
      </div>
      <div className={`${CSSBlock}__expiration`}>
        Expires:{' '}
        {coupon.expirationDate
          ? DateTime.fromJSDate(
              DemmiFS.getDateFromFirestore(coupon.expirationDate)!,
            ).toLocaleString()
          : 'Never expires'}
      </div>
    </div>
  );
};

CouponSidebarButton.displayName = 'CouponSidebarButton';
export default CouponSidebarButton;
