import { Timestamp } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export class CollectionScheduleMocks {
  static generateRandomSchedule = (
    vendorID: string,
    {
      startDate = new Date('2021-01-01'),
      endDate = new Date('2021-01-01'),
    }: {
      startDate: Date;
      endDate: Date;
    },
  ) => {
    const scheduleID = crypto.randomUUID();
    const schedule: DemmiFS.FSVendorTimeSlotSchedule[] = [];
    schedule.push({
      vendorID,
      scheduleID,
      dayOfWeek: DemmiFS.DemmiHelper.DayKeys.MONDAY,
      startTime: 600,
      endTime: 900,
      slotDuration: DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_15,
      capacity: Math.floor(Math.random() * 20),
      startDate: Timestamp.fromDate(startDate),
      endDate: Timestamp.fromDate(endDate),
      breaks: [
        { open: 660, close: 720 },
        { open: 780, close: 795 },
      ],
    });
    return schedule;
  };

  static mockSchedules: DemmiFS.VendorTimeSlotSchedule[] = [
    {
      docID: '1',
      vendorID: '1',
      scheduleID: '1',
      dayOfWeek: DemmiFS.DemmiHelper.DayKeys.MONDAY,
      startTime: 600,
      endTime: 900,
      slotDuration: DemmiFS.VendorHelper.TimeSlotScheduleDuration.MIN_15,
      capacity: 15,
      startDate: Timestamp.now(),
      endDate: Timestamp.now(),
      breaks: [
        { open: 660, close: 720 },
        { open: 780, close: 795 },
      ],
    },
    {
      docID: '2',
      vendorID: '1',
      scheduleID: '1',
      dayOfWeek: DemmiFS.DemmiHelper.DayKeys.TUESDAY,
      startTime: 0,
      endTime: 0,
      slotDuration: 15,
      capacity: 0,
      startDate: Timestamp.now(),
      endDate: Timestamp.now(),
      breaks: [],
    },
    {
      docID: '2',
      vendorID: '1',
      scheduleID: '1',
      dayOfWeek: DemmiFS.DemmiHelper.DayKeys.WEDNESDAY,
      startTime: 0,
      endTime: 0,
      slotDuration: 15,
      capacity: 0,
      startDate: Timestamp.now(),
      endDate: Timestamp.now(),
      breaks: [],
    },
  ];
}
