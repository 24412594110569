import React from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isProd } from '@helpers/app.helper';

import {
  SidebarManaged,
  SidebarManagedButton,
  SidebarProps,
  SidebarSelfManaged,
  SidebarSelfManagedWithSections,
  isSidebarSelfManaged,
  isSidebarSelfManagedWithSections,
} from './_helper';
import SidebarButton from './_managedButton';

const SidebarButtonsView: React.FC<SidebarProps> = props => {
  const CSSBlock = 'sidebar';
  const buttonDivider = <div className={`sidebar-button-divider`} />;

  const customButtonsWithSectionsView = (
    props: SidebarSelfManagedWithSections,
  ) =>
    props.customSectionedButtons.map(
      ({ sectionTitle, sectionIcon, buttons }, i) => {
        const title = (
          <div className={`${CSSBlock}__section-title`} key={i}>
            {sectionIcon && <FontAwesomeIcon icon={sectionIcon} />}
            {sectionTitle}
          </div>
        );
        const buttonsView = buttons.map((button, j) => {
          return [
            ...(j > 0 ? [buttonDivider] : []),
            <SidebarButton
              element={button.element}
              active={button.id === props.active}
              key={j}
              onClick={() => props.onClick(button.id)}
            />,
          ];
        });
        return [title, ...buttonsView];
      },
    );

  const customButtonsView = (props: SidebarSelfManaged) =>
    props.customButtons.map((button, i) => [
      ...(i > 0 ? [buttonDivider] : []),
      <SidebarButton
        element={button}
        active={i === props.active}
        key={i}
        onClick={() => props.onClick(i)}
      />,
    ]);

  const defaultButtonsView = (props: SidebarManaged) =>
    props.buttons
      .filter((button: SidebarManagedButton) =>
        isProd() ? !(button.beta || button.alpha) : true,
      )
      .map((button: SidebarManagedButton, i) => (
        <SidebarButton
          text={button.text}
          faIcon={button.faIcon}
          active={i === props.active}
          key={i}
          onClick={() => props.onClick(button.id)}
          badge={button.alpha ? 'Alpha' : button.beta ? 'Beta' : undefined}
        />
      ));

  return (
    <div className={`${CSSBlock}__buttons`}>
      {props.isLoading ? (
        <ElementLoadingIndicator isLoading={true} />
      ) : isSidebarSelfManaged(props) ? (
        customButtonsView(props as SidebarSelfManaged)
      ) : isSidebarSelfManagedWithSections(props) ? (
        customButtonsWithSectionsView(props as SidebarSelfManagedWithSections)
      ) : (
        defaultButtonsView(props as SidebarManaged)
      )}
    </div>
  );
};

export default SidebarButtonsView;
